import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState, type FunctionComponent } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Turnstile from 'react-turnstile'
import { Button } from '../../../../../../components/Button'
import { TextField } from '../../../../../../components/TextField'
import { useFixedForm } from '../../../../../../hooks'
import { OrganizationInformationMessages } from '../../../../messages'
import { OrganizationInformationFormValues } from '../../../../models'
import { handleTurnstileErrorToastNotification, loadTurnstileToken } from '../../../../utils/turnstile'
import { useOrganizationInformationFormValidation } from '../../useOrganizationInformationFormValidation'

export interface OrganizationInformationProps {
  /** A flag indicating that the request to check if the organization name is available is pending. */
  isCheckOrganizationNameAvailabilityPending: boolean
  /** A flag indicating if the organization name is unavailable. */
  isOrganizationNameAvailable?: boolean
  /** The flag indicating that the request to reserve the organization name is pending. */
  isReserveOrganizationNamePending: boolean
  /** A flag indicating if the component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** A callback that when executed checks if the organization name is available. */
  onCheckOrganizationNameAvailability: (organizationName: string) => void
  /** A callback that when executed reserves the organization name */
  onReserveOrganizationName: (organizationName: string) => void
  /** A callback that when executed tracks a Mixpanel event. */
  onTrackTurnstileMixpanelEvent: (eventName: string, turnstileErrorCode?: string) => void
  /** The turnstile success token callback */
  onTurnstileSuccessToken: (turnstileSuccessToken: string) => void
}

export const OrganizationInformation: FunctionComponent<OrganizationInformationProps> = ({
  isCheckOrganizationNameAvailabilityPending,
  isOrganizationNameAvailable,
  isReserveOrganizationNamePending,
  isStorybookRender,
  onCheckOrganizationNameAvailability,
  onReserveOrganizationName,
  onTrackTurnstileMixpanelEvent,
  onTurnstileSuccessToken,
}) => {
  const intl = useIntl()
  const [isOrganizationNameUpdated, setIsOrganizationNameUpdated] = useState(false)
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false)
  const [hasShownTurnstileError, setHasShownTurnstileError] = useState(false)

  const turnstileToken = loadTurnstileToken(intl, onTrackTurnstileMixpanelEvent, isStorybookRender)
  const organizationInformationValidationScheme = useOrganizationInformationFormValidation()
  const { control, getFieldState, handleSubmit } = useFixedForm<OrganizationInformationFormValues>({
    resolver: yupResolver(organizationInformationValidationScheme),
    onSubmit: ({ organizationName }) => {
      onReserveOrganizationName(organizationName)
    },
    mode: 'onChange',
  })

  const organizationName = useWatch({ control, name: 'organizationName' })

  useEffect(() => {
    const handleCheckIfOrganizationNameIsAvailable = setTimeout(() => {
      if (
        getFieldState('organizationName').isDirty &&
        isOrganizationNameUpdated &&
        !getFieldState('organizationName').invalid
      ) {
        setIsOrganizationNameUpdated(false)
        onCheckOrganizationNameAvailability(organizationName)
      }
    }, 1000)

    return () => clearTimeout(handleCheckIfOrganizationNameIsAvailable)
  }, [getFieldState, isOrganizationNameUpdated, onCheckOrganizationNameAvailability, organizationName])

  const handleTurnstileError = (errorCode: string) => {
    if (!hasShownTurnstileError) {
      onTrackTurnstileMixpanelEvent('Turnstile Error', errorCode)
      handleTurnstileErrorToastNotification(errorCode, intl)
      setHasShownTurnstileError(true)
    }
  }

  return (
    <div className="relative h-screen w-full">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="w-full max-w-2xl">
          <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(OrganizationInformationMessages.title)}</h1>
          <p className="mb-10 text-base">
            {intl.formatMessage(OrganizationInformationMessages.description, {
              permanent_name: (
                <span className="font-bold">
                  {intl.formatMessage(OrganizationInformationMessages.descriptionPermanentNameText)}
                </span>
              ),
            })}
          </p>
          <form onSubmit={handleSubmit}>
            <h2 className="mb-2 text-xl font-bold">
              {intl.formatMessage(OrganizationInformationMessages.organizationDetailsHeader)}
            </h2>
            <p className="mb-8 text-base">
              {intl.formatMessage(OrganizationInformationMessages.organizationDetailsDescription)}
            </p>
            <Controller
              name="organizationName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  isLoading={isCheckOrganizationNameAvailabilityPending}
                  invalid={
                    fieldState.invalid ||
                    (isOrganizationNameAvailable !== undefined && isOrganizationNameAvailable === false)
                  }
                  onChange={(value) => {
                    setIsOrganizationNameUpdated(true)
                    field.onChange(value)
                  }}
                  isDisabled={isReserveOrganizationNamePending}
                  isHelperTextGreen={isOrganizationNameAvailable}
                  helperText={
                    isOrganizationNameAvailable && !isReserveOrganizationNamePending && fieldState.error === undefined
                      ? intl.formatMessage(OrganizationInformationMessages.organizationNameAvailable, {
                          organization_name: organizationName,
                        })
                      : isOrganizationNameAvailable !== undefined &&
                          isOrganizationNameAvailable === false &&
                          !isReserveOrganizationNamePending &&
                          fieldState.error === undefined
                        ? intl.formatMessage(OrganizationInformationMessages.organizationNameTakenErrorText, {
                            organization_name: organizationName,
                          })
                        : intl.formatMessage(OrganizationInformationMessages.organizationNameHelperText)
                  }
                  isFullWidth
                  label={intl.formatMessage(OrganizationInformationMessages.organizationNameFieldLabel)}
                />
              )}
            />

            {turnstileToken && (
              <Turnstile
                className="mb-4"
                onError={handleTurnstileError}
                onSuccess={(token) => onTurnstileSuccessToken(token)}
                onVerify={() => {
                  setIsTurnstileVerified(true)
                }}
                sitekey={turnstileToken}
                theme="light"
              />
            )}

            <Button
              type="submit"
              isDisabled={!isOrganizationNameAvailable || !isTurnstileVerified}
              isLoading={isReserveOrganizationNamePending}
              isFullWidth
            >
              {intl.formatMessage(OrganizationInformationMessages.continueLabel)}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
