import { defineMessages } from 'react-intl'

export const createOrganizationWizardDeleteSuccessContentMessages = defineMessages({
  body: {
    defaultMessage: 'You have successfully deleted {create_organization_wizard_reserved_name}.',
    id: 'R++g7fpw',
    description:
      'The description shown in a toast notification message shown when we successfully delete a pending create organization wizard.',
  },
  title: {
    defaultMessage: 'Successfully deleted your pending organization',
    id: 'mKQ56hz5',
    description: 'The toast notification title shown when we successfully delete a pending create organization wizard.',
  },
})
