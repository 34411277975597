import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ContainerGatewayDetailsMessages } from '../messages'

interface ContainerGatewayDetailsProps {
  /** The client request timeout for the container gateway. */
  clientRequestTimeout?: number
  /** The flag indicating if container gateway authentication is required. */
  isAuthenticationRequired?: boolean
  /** The flag indicating if container gateway is enabled. */
  isEnabled: boolean
  /** The flag indicating if container gateway is limited to single active connection. */
  isLimitedToSingleActiveConnection?: boolean
  /** The load balancer algorithm type for the container gateway. */
  loadBalancerAlgorithm?: string
  /** The port number for the container gateway. */
  port?: number
  /** The server request timeout for the container gateway. */
  serverResponseTimeout?: number
}

export const ContainerGatewayDetails: FunctionComponent<ContainerGatewayDetailsProps> = ({
  clientRequestTimeout,
  isAuthenticationRequired,
  isEnabled,
  isLimitedToSingleActiveConnection,
  loadBalancerAlgorithm,
  port,
  serverResponseTimeout,
}) => {
  const intl = useIntl()

  return isEnabled ? (
    <div className="flex flex-col font-medium">
      <p>{intl.formatMessage(ContainerGatewayDetailsMessages.enabledLabel)}</p>
      {port && (
        <p>
          {intl.formatMessage(ContainerGatewayDetailsMessages.portLabel, {
            port: <span className="font-normal">{port}</span>,
          })}
        </p>
      )}
      <p>
        {intl.formatMessage(ContainerGatewayDetailsMessages.authenticationLabel, {
          is_authentication_required: (
            <span className="font-normal">
              {isAuthenticationRequired
                ? ContainerGatewayDetailsMessages.requiredLabel.defaultMessage
                : ContainerGatewayDetailsMessages.notRequiredLabel.defaultMessage}
            </span>
          ),
        })}
      </p>
      {loadBalancerAlgorithm && (
        <p>
          {intl.formatMessage(ContainerGatewayDetailsMessages.loadBalancerAlgorithmLabel, {
            load_balancer_algorithm: <span className="font-normal">{loadBalancerAlgorithm}</span>,
          })}
        </p>
      )}
      <p>
        {intl.formatMessage(ContainerGatewayDetailsMessages.limitToSingleActiveConnectionLabel, {
          is_limited_to_single_active_connection: (
            <span className="font-normal">
              {isLimitedToSingleActiveConnection
                ? ContainerGatewayDetailsMessages.requiredLabel.defaultMessage
                : ContainerGatewayDetailsMessages.notRequiredLabel.defaultMessage}
            </span>
          ),
        })}
      </p>
      {clientRequestTimeout && (
        <p>
          {intl.formatMessage(ContainerGatewayDetailsMessages.clientRequestTimeoutLabel, {
            client_request_timeout: <span className="font-normal">{clientRequestTimeout}</span>,
          })}
        </p>
      )}
      {serverResponseTimeout && (
        <p>
          {intl.formatMessage(ContainerGatewayDetailsMessages.serverResponseTimeoutLabel, {
            server_request_timeout: <span className="font-normal">{serverResponseTimeout}</span>,
          })}
        </p>
      )}
    </div>
  ) : (
    <p>{intl.formatMessage(ContainerGatewayDetailsMessages.disabledLabel)}</p>
  )
}
