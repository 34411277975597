import { defineMessages } from 'react-intl'

export const InviteTeamMembersMessages = defineMessages({
  emailLabel: {
    defaultMessage: 'Email',
    id: 'oq9V9WiQ',
    description: 'The label for the email input on the invite team members page',
  },
  emailRequired: {
    defaultMessage: 'A valid email address is required to a invite team member.',
    id: '+W8z8QGh',
    description: 'The error message when the email address is not provided.',
  },
  getStartedButtonLabel: {
    defaultMessage: 'Get Started',
    id: 'Gsv1EqXl',
    description: 'The label for the get started button on the invite team members page',
  },
  inviteTeamAndGetStartedButtonLabel: {
    defaultMessage: 'Invite & Get Started',
    id: 'KgpVhuB8',
    description: 'The label for the invite team and get started button on the invite team members page',
  },
  inviteTeamMemberButtonLabel: {
    defaultMessage: 'Invite & Add Another',
    id: 'fcIOs8nk',
    description: 'The label for the invite team member button on the invite team members',
  },
  inviteYourTeamHeader: {
    defaultMessage: 'Invite Your Team',
    id: 'LiHZYAQ6',
    description: 'The header of the invite team members page',
  },
  inviteYourTeamSubheader: {
    defaultMessage: `Enter the email address of a team member below to send them an invitation. When you're finished, click get started.`,
    id: '8JY3KVbT',
    description: 'The subheader of the invite team members page',
  },
  skipAndGetStartedButtonLabel: {
    defaultMessage: 'Skip & Get Started',
    id: 'AotbpdRn',
    description: 'The label for the skip and get started button on the invite team members page',
  },
  title: {
    defaultMessage: 'Congrats, Your Organization is Created!',
    id: 'JiDdY7yB',
    description: 'The title of the invite team members page',
  },
})
