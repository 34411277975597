import { defineMessages } from 'react-intl'

export const DeploymentDetailsMessages = defineMessages({
  imageSourceTitle: {
    defaultMessage: 'Image Source',
    id: 'F/H0ZI9C',
    description: 'Image Source section title',
  },
  url: {
    defaultMessage: 'URL',
    id: 'jIdo+KJ/',
    description: 'Image source url',
  },
  registry: {
    defaultMessage: 'Registry',
    id: 'S1DkuqVu',
    description: 'Image source registry',
  },
  allocatedResourcesTitle: {
    defaultMessage: 'Allocated Resources',
    id: 'w05BHBS9',
    description: 'Allocated Resources title',
  },
  commandTitle: {
    defaultMessage: 'Command',
    id: 'sCNwYoEM',
    description: 'Command section title',
  },
  containerGatewayTitle: {
    defaultMessage: 'Container Gateway',
    id: 'VUEn05TF',
    description: 'Container Gateway section title',
  },
  environmentVariablesTitle: {
    defaultMessage: 'Environment Variables',
    id: 'OrEhwfsi',
    description: 'Environment Variables section title',
  },
  viewEnvironmentVariables: {
    defaultMessage:
      '{env_variables_amount, plural, =0 {No environment variables} other {View {env_variables_amount} Environment Variables}}',
    id: 'AYdWsULJ',
    description: 'Link to view environment variables',
  },
  noEnvironmentVariables: {
    defaultMessage: 'No environment variables',
    id: 'SxC7Jrd9',
    description: 'No environment variables description',
  },
  containerGatewayNotEnabled: {
    defaultMessage: 'Container Gateway is disabled',
    id: 'o84awSDU',
    description: 'The description about the container group Container Gateway status when it is not enabled.',
  },
  externalLoggingServicesTitle: {
    defaultMessage: 'External Logging Services',
    id: 'dwHSn8bC',
    description: 'External Logging Services section title',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'IhOfw7Ny',
    description: 'The label for the port value for the external logging service card.',
  },
  hostLabel: {
    defaultMessage: 'Host',
    id: 'qJjzKC36',
    description: 'The label for the host value for the external logging service card.',
  },
  ingestionKeyLabel: {
    defaultMessage: 'Ingestion Key',
    id: 'DJPETbEH',
    description: 'The label for the ingestion key value for the external logging service card.',
  },
  jobQueueTitle: {
    defaultMessage: 'Job Queue',
    id: 'iHams78A',
    description: 'Job Queue section title',
  },
  livenessProbeTitle: {
    defaultMessage: 'Liveness Probe',
    id: 'cAC95okj',
    description: 'Liveness Probe section title',
  },
  loggingDisabledText: {
    defaultMessage: 'External logging is disabled',
    id: '1fQausRN',
    description:
      'The text shown in the external logging service card on the container group detail page when logging is disabled.',
  },
  newRelicSelectedLabel: {
    defaultMessage: 'Using New Relic',
    id: 'TA9aGZq5',
    description:
      'The text shown in the external logging service card on the container group detail page when new relic is selected.',
  },
  readinessProbeTitle: {
    defaultMessage: 'Readiness Probe',
    id: 'fbFL7Gzz',
    description: 'Readiness Probe section title',
  },
  splunkSelectedLabel: {
    defaultMessage: 'Using Splunk',
    id: 'sDp+gjjP',
    description:
      'The text shown in the external logging service card on the container group detail page when Splunk is selected.',
  },
  startupProbeTitle: {
    defaultMessage: 'Startup Probe',
    id: 'WUfeW1K6',
    description: 'Startup Probe section title',
  },
  tcpSelectedLabel: {
    defaultMessage: 'Using TCP',
    id: '5qabn8rQ',
    description:
      'The text shown in the external logging service card on the container group detail page when TCP is selected.',
  },
  tokenLabel: {
    defaultMessage: 'Token',
    id: 'NAYsCx1g',
    description: 'The label for the token value for the external logging service card.',
  },
})
