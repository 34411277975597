export const convertKeysToCamelCase = (obj: any): any => {
  const toCamelCase = (snakeStr: string): string =>
    snakeStr.replace(/_([a-z])/g, (_: string, letter: string): string => letter.toUpperCase())

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase)
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]): [string, any] => [toCamelCase(key), convertKeysToCamelCase(value)]),
    )
  }
  return obj
}
