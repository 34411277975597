import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { EditDigitalOceanContainerRegistryInputFieldsMessages } from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditDigitalOceanContainerRegistryInputFieldsProps {
  /** The control for the edit container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
}

export const EditDigitalOceanContainerRegistryInputFields: FunctionComponent<
  EditDigitalOceanContainerRegistryInputFieldsProps
> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={EditContainerGroupField.DIGITAL_OCEAN_USERNAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={
              fieldState.error?.message ??
              intl.formatMessage(EditDigitalOceanContainerRegistryInputFieldsMessages.usernameHelperText)
            }
            label={intl.formatMessage(EditDigitalOceanContainerRegistryInputFieldsMessages.usernameLabel)}
            placeholder={intl.formatMessage(
              EditDigitalOceanContainerRegistryInputFieldsMessages.usernamePlaceholderText,
            )}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.DIGITAL_OCEAN_PERSONAL_ACCESS_TOKEN}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={
              fieldState.error?.message ??
              intl.formatMessage(EditDigitalOceanContainerRegistryInputFieldsMessages.personalAccessTokenHelperText)
            }
            label={intl.formatMessage(EditDigitalOceanContainerRegistryInputFieldsMessages.personalAccessTokenLabel)}
            placeholder={intl.formatMessage(
              EditDigitalOceanContainerRegistryInputFieldsMessages.personalAccessTokenPlaceholderText,
            )}
            type="password"
            isFullWidth
          />
        )}
      />
    </>
  )
}
