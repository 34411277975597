import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonMessages } from '../../../../components/Button/messages'
import { Modal } from '../../../../components/block/Modal'
import { DeleteCreateOrganizationWizardModalMessages } from '../../messages'

export interface DeleteCreateOrganizationWizardModalProps {
  /** The reserved organization name. */
  createOrganizationWizardReservedName: string
  /** The flag indicating that the request to delete the organization is pending. */
  isDeleteCreateOrganizationWizardPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Delete` button. */
  onDeleteCreateOrganizationWizard: () => void
}

export const DeleteCreateOrganizationWizardModal: FunctionComponent<DeleteCreateOrganizationWizardModalProps> = ({
  createOrganizationWizardReservedName,
  isDeleteCreateOrganizationWizardPending,
  onCancel,
  onDeleteCreateOrganizationWizard,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(DeleteCreateOrganizationWizardModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">
        {intl.formatMessage(DeleteCreateOrganizationWizardModalMessages.title)}
      </h1>
      <p className="mb-8">
        {intl.formatMessage(DeleteCreateOrganizationWizardModalMessages.description, {
          reserved_organization_name: <span className="text-green-80">{createOrganizationWizardReservedName}</span>,
        })}
      </p>

      <div className="flex max-w-md gap-6">
        <Button type="button" variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(DeleteCreateOrganizationWizardModalMessages.cancelButtonLabel)}
        </Button>
        <Button
          variant="red-filled"
          type="button"
          isDisabled={isDeleteCreateOrganizationWizardPending}
          isLoading={isDeleteCreateOrganizationWizardPending}
          onClick={onDeleteCreateOrganizationWizard}
          isFullWidth
          countdownSeconds={3}
        >
          {intl.formatMessage(ButtonMessages.deleteButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
