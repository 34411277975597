import { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { TURNSTILE_TOKEN } from '../../../config'
import { showToastNotification } from '../../../features/notifications/notificationsSlice'
import { ToastNotificationType } from '../../../notifications'
import {
  getTurnstileChallengeExecutionFailureErrorContent,
  getTurnstileChallengeTimedOutErrorContent,
  getTurnstileGenericClientExecutionErrorContent,
  getTurnstileInconsistentUserAgentErrorContent,
  getTurnstileInitializationProblemsErrorContent,
  getTurnstileTimeProblemErrorContent,
  getTurnstileUnsupportedBrowserErrorContent,
} from '../../../notifications/clientToastNotificationContent/createOrganizationWizard'
import { getTurnstileGenericErrorContent } from '../../../notifications/clientToastNotificationContent/createOrganizationWizard/getTurnstileGenericErrorContent'

/**
 * Loads the Turnstile token from environment variables. Shows a toast notification if the token is missing.
 *
 * @param intl IntlShape instance for localized messages.
 * @returns Turnstile token or null if not found. Also the test site key is returned if the app is running in Storybook.
 */
export const loadTurnstileToken = (
  intl: IntlShape,
  onTrackMixpanelEvent: (eventName: string) => void,
  isStorybookRender?: boolean,
): string | null => {
  const storybookTestSiteKey = '1x00000000000000000000AA'
  if (isStorybookRender) {
    return storybookTestSiteKey
  }
  onTrackMixpanelEvent('Turnstile Initialized')
  if (!TURNSTILE_TOKEN) {
    showToastNotification(getTurnstileGenericErrorContent('100000', intl))
    return null
  }
  return TURNSTILE_TOKEN
}

/** Array of error patterns for turnstile and their corresponding notification content generators. */
const getErrorNotificationContent = (
  errorCode: string,
  intl: IntlShape,
): { body: ReactNode; type: ToastNotificationType; title: string } => {
  const code = parseInt(errorCode, 10)

  switch (true) {
    case code >= 100000 && code <= 100999:
      return getTurnstileInitializationProblemsErrorContent(errorCode, intl)

    case code >= 102000 && code < 107000:
    case code >= 120000 && code <= 120999:
    case code === 200010:
    case code === 110100:
    case code === 110200:
    case code === 110420:
    case code === 110430:
      return getTurnstileGenericErrorContent(errorCode, intl)

    case code === 110510:
      return getTurnstileInconsistentUserAgentErrorContent(errorCode, intl)

    case code === 110500:
      return getTurnstileUnsupportedBrowserErrorContent(errorCode, intl)

    case code >= 110600 && code <= 110609:
    case code >= 110620 && code <= 110629:
      return getTurnstileChallengeTimedOutErrorContent(errorCode, intl)

    case code === 200100:
      return getTurnstileTimeProblemErrorContent(errorCode, intl)

    case code >= 300000 && code <= 300999:
      return getTurnstileGenericClientExecutionErrorContent(errorCode, intl)

    case code >= 600000 && code <= 600999:
      return getTurnstileChallengeExecutionFailureErrorContent(errorCode, intl)

    default:
      return getTurnstileGenericErrorContent(errorCode, intl)
  }
}

/**
 * Displays a toast notification for turnstile based on the error code.
 *
 * @param errorCode The error code returned by the Turnstile service.
 * @param intl IntlShape instance for localized messages.
 */
export const handleTurnstileErrorToastNotification = (errorCode: string, intl: IntlShape) => {
  const notificationContent = getErrorNotificationContent(errorCode, intl)
  showToastNotification(notificationContent)
}
