import { ContainerGroupPriority } from '@saladtechnologies/openapi-cloud-portal-browser'
import { useCallback, useEffect, useState, type FunctionComponent } from 'react'
import { Control, Controller, FieldValues, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { HelpScoutSupportLink } from '../../../../components/block/HelpScoutSupportLink'
import { InlineNotification } from '../../../../components/block/InlineNotification'
import { CheckboxCards } from '../../../../components/forms/CheckboxCards'
import { SearchWithFilters } from '../../../../components/search'
import type { ClientGpuOptions } from '../../../../features/gpuClasses/models'
import { GPUInputMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import {
  checkIfAllGpuClassesAreHighDemand,
  filterGPUOptions,
  generateGPUOptionCards,
  getGpuClassesById,
  sortGPUOptions,
} from './utils'

interface GPUInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The gpu options the user has to select from */
  gpuOptions: ClientGpuOptions
  /** The id attribute for the field. */
  id: CreateContainerGroupFormSectionIdAttributes
}

export const GPUInput: FunctionComponent<GPUInputProps> = ({ control, gpuOptions, id }) => {
  const intl = useIntl()
  const selectedGpus: string[] | undefined = useWatch({ control, name: CreateContainerGroupField.GPU })
  const priority: ContainerGroupPriority | undefined = useWatch({ control, name: CreateContainerGroupField.PRIORITY })
  const [query, setQuery] = useState<string>('')
  const [filter, setFilter] = useState<{ [key: string]: string | undefined }>({
    [intl.formatMessage(GPUInputMessages.searchFilter)]: undefined,
    [intl.formatMessage(GPUInputMessages.searchSortBy)]: intl.formatMessage(GPUInputMessages.sortByHighToLowOption),
  })

  const filterByLabel = intl.formatMessage(GPUInputMessages.searchFilter)
  const sortByLabel = intl.formatMessage(GPUInputMessages.searchSortBy)
  const sortByHighToLowOptionLabel = intl.formatMessage(GPUInputMessages.sortByHighToLowOption)
  const filterByLessThan8GBLabel = intl.formatMessage(GPUInputMessages.filterByLessThan8GB)
  const filterBy8GBPlusLabel = intl.formatMessage(GPUInputMessages.filterBy8GBPlus)
  const filterBy16GBPlusLabel = intl.formatMessage(GPUInputMessages.filterBy16GBPlus)
  const filterBy24GBLabel = intl.formatMessage(GPUInputMessages.filterBy24GB)

  const [filteredAndSortedGPUOptions, setFilteredAndSortedGPUOptions] = useState<ClientGpuOptions>(gpuOptions)

  const handleSearchFilterSortChange = useCallback(
    (query: string, filter: { [key: string]: string | undefined }) => {
      const selectedFilterByOption = filter[filterByLabel]
      const selectedSortByOption = filter[sortByLabel]

      const filteredGPUOptions = filterGPUOptions(
        gpuOptions,
        selectedFilterByOption,
        filterByLessThan8GBLabel,
        filterBy8GBPlusLabel,
        filterBy16GBPlusLabel,
        filterBy24GBLabel,
        query,
      )

      const sortedGPUOptions = sortGPUOptions(filteredGPUOptions, selectedSortByOption, sortByHighToLowOptionLabel)
      setFilteredAndSortedGPUOptions(sortedGPUOptions)
    },
    [
      gpuOptions,
      filterByLessThan8GBLabel,
      filterBy8GBPlusLabel,
      filterBy16GBPlusLabel,
      filterBy24GBLabel,
      filterByLabel,
      sortByHighToLowOptionLabel,
      sortByLabel,
    ],
  )

  useEffect(() => {
    handleSearchFilterSortChange(query, filter)
  }, [gpuOptions, filter, handleSearchFilterSortChange, query])

  const allGPUsSelectedAreHighDemand = checkIfAllGpuClassesAreHighDemand(getGpuClassesById(gpuOptions, selectedGpus))

  return (
    <div id={id}>
      <Controller
        name={CreateContainerGroupField.GPU}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <CheckboxCards
              {...field}
              {...fieldState}
              cards={generateGPUOptionCards(intl, filteredAndSortedGPUOptions, priority)}
              defaultOptionStyles="my-2 font-medium"
              helperText={
                gpuOptions === undefined
                  ? intl.formatMessage(GPUInputMessages.unableToGetGPUOptionsText, {
                      contact_support: (
                        <HelpScoutSupportLink
                          linkedText={intl.formatMessage(GPUInputMessages.contactSupportLinkText)}
                          classNames="text-blue-90 underline"
                        />
                      ),
                    })
                  : intl.formatMessage(GPUInputMessages.helperText)
              }
              invalid={fieldState.invalid || gpuOptions === undefined}
              label={intl.formatMessage(GPUInputMessages.label)}
              name={CreateContainerGroupField.GPU}
              Filter={
                <SearchWithFilters
                  onChange={({ filter, query }) => {
                    setQuery(query)
                    setFilter(filter)
                    handleSearchFilterSortChange(query, filter)
                  }}
                  filters={[
                    {
                      label: filterByLabel,
                      options: [
                        filterByLessThan8GBLabel,
                        filterBy8GBPlusLabel,
                        filterBy16GBPlusLabel,
                        filterBy24GBLabel,
                      ],
                    },
                    {
                      label: sortByLabel,
                      options: [sortByHighToLowOptionLabel],
                    },
                  ]}
                  isFullWidth
                  placeholder={intl.formatMessage(GPUInputMessages.searchInputPlaceholder)}
                  defaultFilter={filter}
                />
              }
            />
          )
        }}
      />
      {allGPUsSelectedAreHighDemand && (
        <InlineNotification
          body={intl.formatMessage(GPUInputMessages.highDemandGpuSelectedNotificationBody)}
          title={intl.formatMessage(GPUInputMessages.highDemandGpuSelectedNotificationTitle)}
          type="info"
        />
      )}
    </div>
  )
}
