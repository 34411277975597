import { defineMessages } from 'react-intl'

export const ContainerGatewayDetailsMessages = defineMessages({
  authenticationLabel: {
    defaultMessage: 'Use Authentication: {is_authentication_required}',
    id: 'NxTi1/D6',
    description: 'The label for the container gateway authentication.',
  },
  clientRequestTimeoutLabel: {
    defaultMessage: 'Client Request Timeout: {client_request_timeout}',
    id: 'M74kLzKs',
    description: 'The label for the container gateway client request timeout.',
  },
  disabledLabel: {
    defaultMessage:
      'Container Gateway is disabled. Your Container Gateway settings cannot be modified on an existing container group. To adjust your settings, please duplicate the container group or create a new one.',
    id: 'TrPKxJx+',
    description: 'The label for whether container gateway is disabled.',
  },
  enabledLabel: {
    defaultMessage: 'Enabled',
    id: 'R0JTVLki',
    description: 'The label for whether container gateway is enabled.',
  },
  limitToSingleActiveConnectionLabel: {
    defaultMessage: 'Limit to Single, Active Connection: {is_limited_to_single_active_connection}',
    id: 'd3KAUQ3b',
    description: 'The label for the container gateway limitations on single active connection.',
  },
  loadBalancerAlgorithmLabel: {
    defaultMessage: 'Load Balancer Algorithm: {load_balancer_algorithm}',
    id: 'PYiHWjHS',
    description: 'The label for the container gateway load balancer algorithm.',
  },
  notRequiredLabel: {
    defaultMessage: 'Not Required',
    id: '2DP9tOqZ',
    description: 'The not required label.',
  },
  portLabel: {
    defaultMessage: 'Port: {port}',
    id: 'eUThvAsd',
    description: 'The label for the container gateway port.',
  },
  requiredLabel: {
    defaultMessage: 'Required',
    id: 'y7FCZQCS',
    description: 'The required label.',
  },
  serverResponseTimeoutLabel: {
    defaultMessage: 'Server Response Timeout: {server_request_timeout}',
    id: 'zyBjywmE',
    description: 'The label for the container gateway server response timeout.',
  },
})
