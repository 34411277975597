import { defineMessages } from 'react-intl'

export const ContainerGatewayFieldsMessages = defineMessages({
  addContainerGatewayLabel: {
    defaultMessage: 'Add Container Gateway',
    id: 'utcmZnMT',
    description: 'The title for button to add a container gateway.',
  },
  clientRequestTimeoutLabel: {
    defaultMessage: 'Client Request Timeout',
    id: 'UEbF7UC+',
    description: 'The label for the client request timeout field.',
  },
  containerGatewayAuthenticationHelperText: {
    defaultMessage: 'When on, requests to containers must be authenticated using your API key',
    id: 'h12fYW5O',
    description: 'The helper text for the container gateway authentication field.',
  },
  containerGatewayAuthenticationLabel: {
    defaultMessage: 'Use Authentication?',
    id: 'qebSRXvz',
    description: 'The label for the container gateway authentication field.',
  },
  containerGatewayDisabledDueToJobQueueLabel: {
    defaultMessage: 'Container Gateway is always disabled when a Job Queue is connected',
    id: 'PNKgJDUy',
    description:
      'The label for the container group container gateway fields indicating that it is disabled because a job queue is selected.',
  },
  containerGatewayDisabledLabel: {
    defaultMessage: 'Container Gateway is disabled',
    id: 'NkZjeY3/',
    description: 'The label for the container group container gateway fields indicating that it is disabled.',
  },
  containerGatewayFieldsLabel: {
    defaultMessage: 'Container Gateway',
    id: '9mLoVkGf',
    description: 'The label for the container group container gateway fields.',
  },
  description: {
    defaultMessage:
      'Allow containers to receive external requests. Requests must be made over HTTPS, and your container must accept incoming IPv6 requests. For help enabling IPv6 support, see our {documentation_link}.',
    id: 'uxG5jO3j',
    description: 'The description for the container group container gateway fields.',
  },
  descriptionDocumentationLinkText: {
    defaultMessage: 'documentation here',
    id: 'a+xeAjli',
    description: 'The description documentation link text for the container group container gateway fields.',
  },
  enableContainerGatewayLabel: {
    defaultMessage: 'Enable Container Gateway. I’ve set up my container to support IPv6.',
    id: '3fHp2Okf',
    description: 'The label for the enable container gateway field.',
  },
  leastNumberOfConnectionsLabel: {
    defaultMessage: 'Least Number Of Connections',
    id: 'SQIXZzOA',
    description: 'The label for the least connections option in the container gateway load balancing field.',
  },
  limitEachServerLabel: {
    defaultMessage: 'Limit each server to a single, active request.',
    id: '3ZAJfnru',
    description: 'The label for the limit each server option in the container gateway load balancing field.',
  },
  loadBalancerAlgorithmLabel: {
    defaultMessage: 'Load Balancer Algorithm',
    id: 'ge4Mc6fa',
    description: 'The label for the container gateway load balancing field.',
  },
  noLabel: {
    defaultMessage: 'No',
    id: 'nin7QwVj',
    description: 'The label for the no option in the container gateway authentication field.',
  },
  portHelperText: {
    defaultMessage: 'Must be a number between 1 to 65535',
    id: 'HXDTVVJn',
    description: 'The helper text for the neworking port input field.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'H1yLuYoS',
    description: 'The label for the neworking port input field.',
  },
  roundRobinLabel: {
    defaultMessage: 'Round Robin',
    id: '+09A1CqT',
    description: 'The label for the round robin option in the container gateway load balancing field.',
  },
  sectionTitle: {
    defaultMessage: 'Container Gateway',
    id: 'gvrs3HxS',
    description:
      'The title for the dialog box that opens when a user wants to edit container gateway fields on the create container group page.',
  },
  serverResponseTimeoutLabel: {
    defaultMessage: 'Server Response Timeout',
    id: 'q9Rkwvkw',
    description: 'The label for the server response timeout field.',
  },
  timeoutHelperText: {
    defaultMessage: 'Must be a number between 1 and 100000 milliseconds',
    id: 'OrW5xiWG',
    description: 'The helper text for the timeout input fields.',
  },
  yesLabel: {
    defaultMessage: 'Yes',
    id: 'UVuvh+sM',
    description: 'The label for the yes option in the container gateway authentication field.',
  },
})
