import { defineMessages } from 'react-intl'

export const EditSelectPrivateRegistryProviderMessages = defineMessages({
  awsElasticLabel: {
    defaultMessage: 'AWS Elastic Container Registry',
    id: 'pohU/RBB',
    description: 'The label for the AWS Elastic private registry option.',
  },
  azureLabel: {
    defaultMessage: 'Azure Container Registry',
    id: 'OyDrQCeB',
    description: 'The label for the Azure private registry option.',
  },
  digitalOceanContainerRegistryLabel: {
    defaultMessage: 'DigitalOcean Container Registry',
    id: 'B5dL2FnO',
    description: 'The label for the DigitalOcean Container private registry option.',
  },
  dockerHubLabel: {
    defaultMessage: 'Docker Hub',
    id: 'owIUjQAB',
    description: 'The label for the Docker Hub private registry option.',
  },
  gitHubLabel: {
    defaultMessage: 'GitHub Container Registry',
    id: '2EA65iL6',
    description: 'The label for the GitHub private registry option.',
  },
  googleArtifactRegistryLabel: {
    defaultMessage: 'Google Artifact Registry',
    id: '72Rp3zwr',
    description: 'The label for the Google Artifact Registry private registry option.',
  },
  googleContainerRegistryLabel: {
    defaultMessage: 'Google Container Registry',
    id: 'm8/WYD7B',
    description: 'The label for the Google Container Registry private registry option.',
  },
  label: {
    defaultMessage: 'What Service Are You Using?',
    id: 'y9R+FQvT',
    description: 'The label for the private registry provider select field.',
  },
  placeholderText: {
    defaultMessage: 'Please select an option',
    id: 'PjTOyYz5',
    description: 'The placeholder text for the select private registry provider field.',
  },
  selfHostedLabel: {
    defaultMessage: 'Self-Hosted (Basic Auth)',
    id: 'jJYd1Nb0',
    description: 'The label for the Self-Hosted (Basic Auth) private registry option.',
  },
  quayLabel: {
    defaultMessage: 'Quay Container Registry',
    id: '44H4YbGX',
    description: 'The label for the Quay private registry option.',
  },
})
