import { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../../../layouts/threeColumnLayout'
import { CreateOrganizationWizardPageSteps } from '../../models'
import { CreateOrganizationWizardProgressBar } from '../CreateOrganizationWizardProgressBar.tsx'
import { InviteTeamMembers } from '../InviteTeamMembers/components/InviteTeamMembersMainContent'

export interface InviteTeamMembersPageProps {
  /** A flag indicating if the user has selected the free trial path */
  isFreeTrial?: boolean
  /** A flag indicating that the request to invite a team member is pending. */
  isInviteTeamMemberPending: boolean
  /** The flag indicating that the request to invite a team member is successful. */
  isInviteTeamMemberSuccessful: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed concludes the create organization wizard */
  onGetStarted: () => void
  /** The callback handler for inviting a team member. */
  onInviteTeamMember: (email: string) => void
}

export const InviteTeamMembersPage: FunctionComponent<InviteTeamMembersPageProps> = ({
  isFreeTrial,
  isInviteTeamMemberPending,
  isInviteTeamMemberSuccessful,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onGetStarted,
  onInviteTeamMember,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar
          currentPage={CreateOrganizationWizardPageSteps.InviteTeamMembers}
          isFreeTrial={isFreeTrial}
        />
      }
      MainColumn={
        <InviteTeamMembers
          isInviteTeamMemberPending={isInviteTeamMemberPending}
          isInviteTeamMemberSuccessful={isInviteTeamMemberSuccessful}
          onGetStarted={onGetStarted}
          onInviteTeamMember={onInviteTeamMember}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      isMainColumnWithoutScrollStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
