import { defineMessages } from 'react-intl'

export const CreateOrganizationWizardCardMessages = defineMessages({
  continueButtonLabel: {
    defaultMessage: 'Continue',
    id: 'Fx/3D0UA',
    description: 'The label for the continue button.',
  },
  createOrganizationWizardCardTitle: {
    defaultMessage: `You haven't finished creating...`,
    id: 'C9nk4Lzn',
    description: 'The title for the create organization wizard card.',
  },
  deleteButtonLabel: {
    defaultMessage: 'Delete',
    id: 'n0HIoN4X',
    description: 'The label for the delete button.',
  },
  nameSelectionPendingDefaultText: {
    defaultMessage: 'Name Selection Pending',
    id: 'faYvA4DU',
    description: 'The text we show if we do not have a reserved name.',
  },
})
