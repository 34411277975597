import { defineMessages } from 'react-intl'

export const createOrganizationWizardDeleteGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to delete your pending organization. Try again and if this issue persists {contact_support}.',
    id: '4iXhFoHT',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to delete their organization wizard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'J7Pk4lQw',
    description:
      'The text shown for the contact support link in the delete organization wizard error toast notification.',
  },
  title: {
    defaultMessage: 'Error deleting your pending organization',
    id: 'GqjnA7gA',
    description:
      'The toast notification title shown when we encounter an error on their request to delete their organization wizard.',
  },
})
