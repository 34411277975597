import { loadStripe } from '@stripe/stripe-js'
import { STORYBOOK_STRIPE_API_KEY, STRIPE_API_KEY } from '../../../config'
import { showToastNotification } from '../../../features/notifications/notificationsSlice'
import { getLoadStripeErrorMessage } from '../../../notifications/clientToastNotificationContent/billing/getLoadStripeErrorMessage'

export const stripePromise = (intl: any, isStorybook?: boolean) => {
  const stripeApiKey = isStorybook ? STORYBOOK_STRIPE_API_KEY : STRIPE_API_KEY
  return loadStripe(stripeApiKey as string).catch(() => showToastNotification(getLoadStripeErrorMessage(intl)))
}

export const stripeAppearanceVariables = {
  colorPrimary: '#53a626',
  colorBackground: '#ffffff',
  colorText: '#0a2133',
  colorDanger: '#ca3c24',
  fontFamily: 'Mallory, system-ui, sans-serif',
  spacingUnit: '4px',
  borderRadius: '4px',
}
