import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { trackMixpanelEvent } from '../../../../features/analytics/analyticsSlice'
import { selectCreateOrganizationWizard } from '../../../../features/createOrganizationWizard/createOrganizationWizardSelector'
import { getCreateOrganizationWizard } from '../../../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { inviteTeamMember } from '../../../../features/inviteTeamMember/inviteTeamMemberSlice'
import { selectIsLeftColumnShowing } from '../../../../features/navigationBar/navigationBarSelectors'
import { selectRequestStatus } from '../../../../features/requestStatus/requestStatusSelectors'
import { setLeftColumnShowing } from '../../../../mockFeatures/mockNavigationBar/mockNavigationBarSlice'
import { CreateOrganizationWizardPath } from '../../../../routes/routePaths'
import { getSelectProductPagePath } from '../../../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { InviteTeamMembersPage } from './InviteTeamMembersPage'

export const ConnectedInviteTeamMembersPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isInviteTeamMemberPending = useAppSelector(
    (state) => selectRequestStatus(state, 'inviteTeamMember') === 'pending',
  )
  const isInviteTeamMemberSuccessful = useAppSelector(
    (state) => selectRequestStatus(state, 'inviteTeamMember') === 'succeeded',
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const createOrganizationWizard = useAppSelector((state) =>
    selectCreateOrganizationWizard(state, createOrganizationWizardId),
  )
  const organizationName = createOrganizationWizard?.createOrganizationWizard.organizationReservedName || ''
  const isFreeTrialEnabled = createOrganizationWizard?.createOrganizationWizard?.initialCredit === 0
  const onInviteTeamMember = useCallback(
    (email: string) => {
      dispatch(inviteTeamMember({ organizationName, email }))
      dispatch(
        trackMixpanelEvent({
          event: 'Team Member Invited From Create Organization Wizard',
          properties: { path: CreateOrganizationWizardPath.InviteTeamMembers, email, organizationName },
        }),
      )
    },
    [organizationName, dispatch],
  )

  const onGetStarted = useCallback(() => {
    navigate(getSelectProductPagePath(organizationName, 'default'))
  }, [navigate, organizationName])

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCreateOrganizationWizard({ createOrganizationWizardId }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: CreateOrganizationWizardPath.InviteTeamMembers, isFreeTrialEnabled },
      }),
    )
  }, [dispatch, createOrganizationWizardId, isFreeTrialEnabled])

  return (
    <InviteTeamMembersPage
      isFreeTrial={isFreeTrialEnabled}
      isInviteTeamMemberPending={isInviteTeamMemberPending}
      isInviteTeamMemberSuccessful={isInviteTeamMemberSuccessful}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onGetStarted={onGetStarted}
      onInviteTeamMember={onInviteTeamMember}
    />
  )
}
