import {
  ContainerGroupNetworking,
  CreateContainerGroupNetworkingLoadBalancerEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { ContainerGatewayDetails } from '../../../../components/containerGroups/ContainerGatewayDetails'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { Select } from '../../../../components/forms/Select'
import { EditContainerGatewayFieldsMessages, EditContainerGroupMainContentMessages } from '../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../models'
import { EditContainerGroupField } from '../../models'
import { checkIfIContainerGatewayFieldsHaveErrors, containerGatewayFieldsList } from './utils'

interface EditContainerGatewayFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the fields. */
  id: EditContainerGroupFormSectionIdAttributes
  /** The flag indicating if the containerGateway side panel is open. */
  isContainerGatewaySidePanelOpen: boolean
  /** The container group networking data. */
  networking?: ContainerGroupNetworking | null
  /** The function that sets the state of the isContainerGatewaySidePanelOpen state. */
  setIsContainerGatewaySidePanelOpen: (isContainerGatewaySidePanelOpen: boolean) => void
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const EditContainerGatewayFields: FunctionComponent<EditContainerGatewayFieldsProps> = ({
  control,
  id,
  isContainerGatewaySidePanelOpen,
  networking,
  setIsContainerGatewaySidePanelOpen,
  trigger,
}) => {
  const intl = useIntl()
  const { errors } = useFormState({ control })
  const isContainerGatewayAuthenticationRequired = useWatch({
    control,
    name: EditContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION,
  })
  const isContainerGatewayEnabled = useWatch({ control, name: EditContainerGroupField.CONTAINER_GATEWAY_ENABLED })
  const containerGatewayPort = useWatch({ control, name: EditContainerGroupField.CONTAINER_GATEWAY_PORT })
  const containerGatewayFieldsHaveErrors = checkIfIContainerGatewayFieldsHaveErrors(errors)
  const [isContainerGatewayAuthenticated, setIsContainerGatewayAuthenticated] = useState<boolean>(
    isContainerGatewayAuthenticationRequired,
  )
  const { clientRequestTimeout, loadBalancer, serverResponseTimeout, singleConnectionLimit } = networking || {}

  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(containerGatewayFieldsList).then((isValid) => {
      if (isValid) {
        setIsContainerGatewaySidePanelOpen(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={containerGatewayFieldsHaveErrors}
        isDisabled={!isContainerGatewayEnabled}
        onEditForm={() => setIsContainerGatewaySidePanelOpen(true)}
        title={intl.formatMessage(EditContainerGatewayFieldsMessages.containerGatewayFieldsLabel)}
      >
        <ContainerGatewayDetails
          clientRequestTimeout={clientRequestTimeout}
          isAuthenticationRequired={isContainerGatewayAuthenticated}
          isEnabled={isContainerGatewayEnabled}
          isLimitedToSingleActiveConnection={singleConnectionLimit}
          loadBalancerAlgorithm={loadBalancer}
          port={containerGatewayPort}
          serverResponseTimeout={serverResponseTimeout}
        />
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button variant="green-filled" form="updateContainerGatewayFieldsForm" isFullWidth type="submit">
            {intl.formatMessage(EditContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isContainerGatewaySidePanelOpen}
        onClose={() => setIsContainerGatewaySidePanelOpen(false)}
        title={intl.formatMessage(EditContainerGatewayFieldsMessages.sectionTitle)}
      >
        <form onSubmit={handleValidateFieldsBeforeClose} id="updateContainerGatewayFieldsForm">
          <div className="mt-12 px-10">
            <h2 className="mb-2 text-3xl font-bold">
              {intl.formatMessage(EditContainerGatewayFieldsMessages.sectionTitle)}
            </h2>

            <p className="mb-10">
              {intl.formatMessage(EditContainerGatewayFieldsMessages.description, {
                documentation_link: (
                  <Link url="https://docs.salad.com/products/sce/networking">
                    {intl.formatMessage(EditContainerGatewayFieldsMessages.descriptionDocumentationLinkText)}
                  </Link>
                ),
              })}
            </p>

            <div className="mb-6 text-neutral-60">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_ENABLED}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...fieldState}
                      isChecked={field.value as boolean}
                      isDisabled
                      id="containerGateway-enabled-checkbox"
                      label={intl.formatMessage(EditContainerGatewayFieldsMessages.enableContainerGatewayLabel)}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_PORT}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      helperText={intl.formatMessage(EditContainerGatewayFieldsMessages.portHelperText)}
                      label={intl.formatMessage(EditContainerGatewayFieldsMessages.portLabel)}
                      isDisabled={!isContainerGatewayEnabled}
                      isFullWidth
                      min={1}
                      max={65535}
                      type="number"
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION}
                control={control}
                defaultValue={isContainerGatewayAuthenticated}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      {...fieldState}
                      defaultSelectedValue={field.value}
                      helperText={intl.formatMessage(
                        EditContainerGatewayFieldsMessages.containerGatewayAuthenticationHelperText,
                      )}
                      labelText={intl.formatMessage(
                        EditContainerGatewayFieldsMessages.containerGatewayAuthenticationLabel,
                      )}
                      isDisabled
                      isFullWidth
                      isPlaceholderOptionDisabled
                      onChange={(value) => {
                        const booleanValue = value === 'true'
                        field.onChange(booleanValue)
                        // This is needed to trigger the UI updating for the protocol field
                        setIsContainerGatewayAuthenticated(booleanValue)
                      }}
                      options={[
                        {
                          label: intl.formatMessage(EditContainerGatewayFieldsMessages.yesLabel),
                          value: true,
                        },
                        {
                          label: intl.formatMessage(EditContainerGatewayFieldsMessages.noLabel),
                          value: false,
                        },
                      ]}
                    />
                  )
                }}
              />
            </div>
            <div className="mb-9">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_LOAD_BALANCER}
                control={control}
                defaultValue={loadBalancer}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      {...fieldState}
                      defaultSelectedValue={field.value}
                      labelText={intl.formatMessage(EditContainerGatewayFieldsMessages.loadBalancerAlgorithmLabel)}
                      isDisabled
                      isFullWidth
                      isPlaceholderOptionDisabled
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                      options={[
                        {
                          label: intl.formatMessage(EditContainerGatewayFieldsMessages.leastNumberOfConnectionsLabel),
                          value: CreateContainerGroupNetworkingLoadBalancerEnum.LeastNumberOfConnections,
                        },
                        {
                          label: intl.formatMessage(EditContainerGatewayFieldsMessages.roundRobinLabel),
                          value: CreateContainerGroupNetworkingLoadBalancerEnum.RoundRobin,
                        },
                      ]}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-9 text-neutral-60">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_LIMIT_SERVER}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...fieldState}
                      isDisabled
                      isChecked={!!singleConnectionLimit}
                      id="containerGateway-enabled-checkbox-limit-server"
                      label={intl.formatMessage(EditContainerGatewayFieldsMessages.limitEachServerLabel)}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT}
                control={control}
                defaultValue={100000}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={clientRequestTimeout}
                      label={intl.formatMessage(EditContainerGatewayFieldsMessages.clientRequestTimeoutLabel)}
                      isDisabled
                      isFullWidth
                      min={1}
                      max={100000}
                      type="number"
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT}
                control={control}
                defaultValue={serverResponseTimeout}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      label={intl.formatMessage(EditContainerGatewayFieldsMessages.serverResponseTimeoutLabel)}
                      isDisabled
                      isFullWidth
                      min={1}
                      max={100000}
                      type="number"
                    />
                  )
                }}
              />
            </div>
          </div>
        </form>
      </SidePanelModal>
    </div>
  )
}
