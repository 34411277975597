import type { ContainerGroupPriority, RamOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { Control, FieldValues } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import type { AllocatedResources } from '../../components/containerGroups/models'
import type { ClientGpuOptions } from '../../features/gpuClasses/models'
import { getCPUCoreCostPerHour } from '../../utils'
import { getSelectedRamOption } from '../../utils/ramOptions'
import { getGpuClassPriorityPrice } from '../../utils/retrieveContainerGroupOptions'
import type { CreateContainerGroupValues } from './models'
import { CreateContainerGroupField } from './models'

export const useGetEstimatedCost = (
  control: Control<FieldValues, CreateContainerGroupValues>,
  gpuOptions: ClientGpuOptions,
  ramOptions: RamOption[] | undefined,
): { allocatedResources: AllocatedResources; replicaCount: number; priority: ContainerGroupPriority | undefined } => {
  const gpu: string[] = useWatch({ control, name: CreateContainerGroupField.GPU })
  const memory = useWatch({ control, name: CreateContainerGroupField.MEMORY })
  const priority: ContainerGroupPriority | undefined = useWatch({ control, name: CreateContainerGroupField.PRIORITY })
  const replicaCount = useWatch({ control, name: CreateContainerGroupField.REPLICA_COUNT })
  const vCPU = useWatch({
    control,
    name: CreateContainerGroupField.VCPUS,
  })
  const selectedRamOption = getSelectedRamOption(ramOptions, memory)
  const ramCostPerHour = selectedRamOption?.costPerHour ? selectedRamOption.costPerHour : 0

  const allocatedResources = {
    cpu: {
      cores: vCPU,
      costPerHour: getCPUCoreCostPerHour(vCPU),
    },
    gpuClasses: gpu?.map((gpu) => ({
      class: gpu,
      costPerHour: getGpuClassPriorityPrice(gpuOptions, gpu, priority),
    })),
    memory: {
      gb: memory / 1024,
      costPerHour: ramCostPerHour,
    },
  }

  return {
    allocatedResources,
    replicaCount,
    priority,
  }
}
