import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { RadioCardsMessages } from './messages'
import type { RadioCardOptions } from './models'

interface RadioCardsProps {
  /** The card options the user has to select from. */
  cards: RadioCardOptions
  /** The default selected value. */
  value?: any
  /** The flag indicating if the Radio Cards should have no margin bottom (optional). */
  hasNoMarginBottom?: boolean
  /** The flag indicating that "None Selected" should be an option. */
  hasNoneSelectedOption?: boolean
  /** The text to be shown under the label. */
  helperText?: string | ReactNode
  /** A value indicating the Radio Cards error state */
  invalid?: boolean
  /** Whether the cards should be displayed inline */
  isInline?: boolean
  /** The label for the radio cards input. */
  label: string
  /** The name for the radio cards input. */
  name: string
  /** The onChange handler. */
  onChange: (value: string | undefined) => void
}

export const RadioCardsTemp: FunctionComponent<RadioCardsProps> = forwardRef<HTMLInputElement, RadioCardsProps>(
  (
    { cards, hasNoMarginBottom, hasNoneSelectedOption, helperText, invalid, isInline, label, name, onChange, value },
    ref,
  ) => {
    const intl = useIntl()

    const checkedValue = cards.some((card) => card.value === value) ? value : cards[0]?.value

    const cardOptions: RadioCardOptions =
      hasNoneSelectedOption && cards.length > 0
        ? [
            {
              content: (
                <p className="text-base font-medium">{intl.formatMessage(RadioCardsMessages.noneSelectedLabel)}</p>
              ),
              value: undefined,
            },
            ...cards,
          ]
        : cards

    return (
      <fieldset className={classNames('w-full', { 'mb-10': !hasNoMarginBottom })}>
        <legend
          className={classNames('mb-1 text-base font-bold', {
            'text-red-70': invalid,
          })}
        >
          {label}
        </legend>
        <p
          className={classNames('text-sm', {
            'text-red-70': invalid,
          })}
        >
          {helperText}
        </p>
        <div
          className={classNames('mt-4 flex gap-5', {
            'flex-wrap': !isInline,
          })}
        >
          {cardOptions.map((card, index) => (
            <div
              className={classNames('flex', {
                'min-w-0 flex-1': isInline,
              })}
              key={index}
            >
              <div
                className={classNames('relative', {
                  'w-full': isInline,
                  'w-80': !isInline,
                })}
              >
                <input
                  onChange={(event) => (event.target.checked ? onChange(card.value) : undefined)}
                  className="peer absolute block size-full cursor-pointer opacity-0"
                  checked={checkedValue === card.value}
                  disabled={card.disabled}
                  id={`${card.value + index}`}
                  name={name}
                  ref={ref}
                  type="radio"
                  value={card.value}
                />
                <label
                  // TODO: Have better svg selector that doesn't depend on knowing the structure of the component in the children prop
                  className="flex size-full flex-col justify-center rounded-lg border border-transparent bg-neutral-10 p-2 shadow-md peer-checked:border-green-50 peer-checked:text-green-70 peer-hover:border-green-50 peer-focus:border-green-70 peer-disabled:border-transparent peer-disabled:opacity-50 peer-checked:[&>div>svg]:fill-green-70"
                  htmlFor={`${card.value + index}`}
                >
                  {card.content}
                </label>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    )
  },
)
