import { defineMessages } from 'react-intl'

export const StripeMessages = defineMessages({
  bodyText: {
    defaultMessage: 'This could be caused by a connectivity issue. Please ',
    id: 'iZI+TJzS',
    description: 'Text explaining a possible connectivity issue for Stripe.',
  },
  refreshThePageLinkText: {
    defaultMessage: 'refresh the page',
    id: 'UJj7nIq1',
    description: 'Text for the refresh the page link in Stripe error message.',
  },
  stripeErrorTitle: {
    defaultMessage: 'Error loading payment information',
    id: 'Pq7dWhLj',
    description: 'Title for the Stripe error notification.',
  },
})
