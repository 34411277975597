import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../../../features/analytics/analyticsSlice'
import { selectCreateOrganizationWizard } from '../../../../features/createOrganizationWizard/createOrganizationWizardSelector'
import {
  confirmPaymentSummary,
  getCreateOrganizationWizard,
  stopPollingForCreateOrganizationWizardSubmission,
} from '../../../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectIsLeftColumnShowing } from '../../../../features/navigationBar/navigationBarSelectors'
import { selectRequestStatus } from '../../../../features/requestStatus/requestStatusSelectors'
import { setLeftColumnShowing } from '../../../../mockFeatures/mockNavigationBar/mockNavigationBarSlice'
import { CreateOrganizationWizardPath } from '../../../../routes/routePaths'
import { getCreateOrganizationWizardAddPaymentMethodPagePath } from '../../../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { PaymentSummaryPage } from './PaymentSummaryPage'

export const ConnectedPaymentSummaryPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const createOrganizationWizard = useAppSelector((state) =>
    selectCreateOrganizationWizard(state, createOrganizationWizardId),
  )
  const isGetOrganizationWizardPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getCreateOrganizationWizard') === 'pending',
  )
  const creditCardExpirationDate = `${createOrganizationWizard?.createOrganizationWizard?.paymentMethodDetails?.expirationMonth}/${createOrganizationWizard?.createOrganizationWizard?.paymentMethodDetails?.expirationYear}`
  const creditCardLastFourDigits = createOrganizationWizard?.createOrganizationWizard?.paymentMethodDetails?.lastFour
  const creditCardBrand = createOrganizationWizard?.createOrganizationWizard?.paymentMethodDetails?.brand
  const estimatedTax = createOrganizationWizard?.createOrganizationWizard?.estimatedTax
  const initialCredit = createOrganizationWizard?.createOrganizationWizard?.initialCredit ?? 0
  const organizationReservedName = createOrganizationWizard?.createOrganizationWizard?.organizationReservedName
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isEstimatedTaxLoading = estimatedTax === undefined || estimatedTax === null
  const isPaymentDetailsLoading =
    createOrganizationWizard?.createOrganizationWizard?.paymentMethodDetails?.expirationMonth === undefined ||
    createOrganizationWizard?.createOrganizationWizard?.paymentMethodDetails?.expirationYear === undefined ||
    creditCardLastFourDigits === undefined

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCreateOrganizationWizard({ createOrganizationWizardId }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: CreateOrganizationWizardPath.PaymentMethodSummary },
      }),
    )
  }, [dispatch, createOrganizationWizardId])

  const handleOnBack = useCallback(() => {
    navigate(getCreateOrganizationWizardAddPaymentMethodPagePath(createOrganizationWizardId))
  }, [createOrganizationWizardId, navigate])

  const handleConfirmPaymentSummary = useCallback(() => {
    if (organizationReservedName) {
      dispatch(confirmPaymentSummary({ organizationReservedName, createOrganizationWizardId }))
    }
  }, [dispatch, organizationReservedName, createOrganizationWizardId])

  useEffect(() => {
    return () => {
      dispatch(stopPollingForCreateOrganizationWizardSubmission({ createOrganizationWizardId }))
    }
  }, [createOrganizationWizardId, dispatch])

  return isGetOrganizationWizardPending || isEstimatedTaxLoading || isPaymentDetailsLoading ? (
    <LoadingPage />
  ) : (
    <PaymentSummaryPage
      creditCardBrand={creditCardBrand}
      creditCardExpirationDate={creditCardExpirationDate}
      creditCardLastFourDigits={creditCardLastFourDigits}
      estimatedTax={estimatedTax}
      initialCredit={initialCredit}
      isConfirmPaymentSummaryPending={false}
      isLeftColumnOpen={isLeftColumnOpen}
      onBack={handleOnBack}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onConfirmPaymentSummary={handleConfirmPaymentSummary}
    />
  )
}
