import { catchError, concat, defer, delay, filter, from, mergeMap, of, repeat, switchMap, takeUntil, timer } from 'rxjs'
import { ContainerGroupsAPI, OrganizationsAPI, ProjectsAPI, QueuesAPI } from '../apiMethods'
import { containerGroupsAddedToOrganization } from '../features/containerGroups/containerGroupsSlice'
import {
  getJobQueueDetailsPageData,
  jobQueueAdded,
  stopPollingForJobQueueDetails,
} from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getJobQueueDetailsPageDataErrorContent } from '../notifications/clientToastNotificationContent/jobQueues'
import { getJobQueuesPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetJobQueueDetailsPageData: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getJobQueueDetailsPageData.match),
    switchMap(({ payload: { jobQueueName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'pending' })),
        defer(() =>
          from(
            Promise.all([
              QueuesAPI.getQueue({
                organizationName,
                projectName,
                queueName: jobQueueName,
              }),
              OrganizationsAPI.getOrganization({
                organizationName,
              }),
              ProjectsAPI.listProjects({
                organizationName,
              }),
              ContainerGroupsAPI.listContainerGroups({
                organizationName,
                projectName,
              }),
            ]),
          ),
        ).pipe(
          repeat({
            delay: () => {
              const fiveSecondsInMS = 5000
              return timer(fiveSecondsInMS)
            },
          }),
          mergeMap(([jobQueueResponse, organizationResponse, projectsResponse, containerGroupsResponse]) =>
            concat(
              of(
                jobQueueAdded({
                  organizationName,
                  projectName,
                  jobQueue: jobQueueResponse,
                }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: organizationResponse.name,
                  projects: projectsResponse.items,
                }),
                containerGroupsAddedToOrganization({
                  organizationName,
                  projectName,
                  containerGroups: containerGroupsResponse.items,
                }),
                setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'failed' }),
                showToastNotification(getJobQueueDetailsPageDataErrorContent(intl)),
                navigateTo({ path: getJobQueuesPagePath(organizationName, projectName) }),
                stopPollingForJobQueueDetails(),
              ),
              of(setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          takeUntil(action$.pipe(filter(stopPollingForJobQueueDetails.match))),
        ),
      ),
    ),
  )
}
