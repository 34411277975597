/**
 * This enum must be ordered based on when they appear on the page. This is needed to make it easier to identify which
 * section to scroll to when there is a validation error.
 */
export enum EditContainerGroupField {
  DISPLAY_NAME = 'displayName',

  IMAGE_SOURCE = 'imageSource',
  IMAGE_TYPE = 'imageType',
  PRIVATE_REGISTRY_PROVIDER = 'privateRegistryProvider',
  AWS_ELASTIC_ACCESS_KEY_ID = 'awsElasticAccessKeyId',
  AWS_ELASTIC_SECRET_ACCESS_KEY = 'awsElasticSecretAccessKey',
  AZURE_PASSWORD = 'azurePassword',
  AZURE_USERNAME = 'azureUsername',
  DIGITAL_OCEAN_PERSONAL_ACCESS_TOKEN = 'digitalOceanPersonalAccessToken',
  DIGITAL_OCEAN_USERNAME = 'digitalOceanUsername',
  DOCKER_HUB_PERSONAL_ACCESS_TOKEN = 'dockerHubPersonalAccessToken',
  DOCKER_HUB_USERNAME = 'dockerHubUsername',
  GIT_HUB_PERSONAL_ACCESS_TOKEN = 'gitHubPersonalAccessToken',
  GIT_HUB_USERNAME = 'gitHubUsername',
  GOOGLE_ARTIFACT_JSON_KEY = 'googleArtifactJsonKey',
  GOOGLE_CONTAINER_JSON_KEY = 'googleContainerJsonKey',
  QUAY_PASSWORD = 'quayPassword',
  QUAY_USERNAME = 'quayUsername',
  SELF_HOSTED_USERNAME = 'selfHostedUsername',
  SELF_HOSTED_PASSWORD = 'selfHostedPassword',

  REPLICA_COUNT = 'replicaCount',

  VCPUS = 'vcpus',

  MEMORY = 'memory',

  GPU = 'gpu',

  PRIORITY = 'priority',

  DISK_SPACE = 'diskSpace',

  STARTUP_PROBE_COMMAND = 'startupProbeCommand',
  STARTUP_PROBE_COMMAND_ARGUMENTS = 'startupProbeCommandArguments',
  STARTUP_PROBE_ENABLED = 'startupProbeEnabled',
  STARTUP_PROBE_FAILURE_THRESHOLD = 'startupProbeFailureThreshold',
  STARTUP_PROBE_HEADERS = 'startupProbeHeaders',
  STARTUP_PROBE_INITIAL_DELAY_SECONDS = 'startupProbeInitialDelaySeconds',
  STARTUP_PROBE_PATH = 'startupProbePath',
  STARTUP_PROBE_PERIOD_SECONDS = 'startupProbePeriodSeconds',
  STARTUP_PROBE_PORT = 'startupProbePort',
  STARTUP_PROBE_PROTOCOL = 'startupProbeProtocol',
  STARTUP_PROBE_SERVICE = 'startupProbeService',
  STARTUP_PROBE_SUCCESS_THRESHOLD = 'startupProbeSuccessThreshold',
  STARTUP_PROBE_TIMEOUT_SECONDS = 'startupProbeTimeoutSeconds',

  LIVENESS_PROBE_COMMAND = 'livenessProbeCommand',
  LIVENESS_PROBE_COMMAND_ARGUMENTS = 'livenessProbeCommandArguments',
  LIVENESS_PROBE_ENABLED = 'livenessProbeEnabled',
  LIVENESS_PROBE_FAILURE_THRESHOLD = 'livenessProbeFailureThreshold',
  LIVENESS_PROBE_HEADERS = 'livenessProbeHeaders',
  LIVENESS_PROBE_INITIAL_DELAY_SECONDS = 'livenessProbeInitialDelaySeconds',
  LIVENESS_PROBE_PATH = 'livenessProbePath',
  LIVENESS_PROBE_PERIOD_SECONDS = 'livenessProbePeriodSeconds',
  LIVENESS_PROBE_PORT = 'livenessProbePort',
  LIVENESS_PROBE_PROTOCOL = 'livenessProbeProtocol',
  LIVENESS_PROBE_SERVICE = 'livenessProbeService',
  LIVENESS_PROBE_SUCCESS_THRESHOLD = 'livenessProbeSuccessThreshold',
  LIVENESS_PROBE_TIMEOUT_SECONDS = 'livenessProbeTimeoutSeconds',

  READINESS_PROBE_COMMAND = 'readinessProbeCommand',
  READINESS_PROBE_COMMAND_ARGUMENTS = 'readinessProbeCommandArguments',
  READINESS_PROBE_ENABLED = 'readinessProbeEnabled',
  READINESS_PROBE_FAILURE_THRESHOLD = 'readinessProbeFailureThreshold',
  READINESS_PROBE_HEADERS = 'readinessProbeHeaders',
  READINESS_PROBE_INITIAL_DELAY_SECONDS = 'readinessProbeInitialDelaySeconds',
  READINESS_PROBE_PATH = 'readinessProbePath',
  READINESS_PROBE_PERIOD_SECONDS = 'readinessProbePeriodSeconds',
  READINESS_PROBE_PORT = 'readinessProbePort',
  READINESS_PROBE_PROTOCOL = 'readinessProbeProtocol',
  READINESS_PROBE_SERVICE = 'readinessProbeService',
  READINESS_PROBE_SUCCESS_THRESHOLD = 'readinessProbeSuccessThreshold',
  READINESS_PROBE_TIMEOUT_SECONDS = 'readinessProbeTimeoutSeconds',

  COMMAND = 'command',
  COMMAND_ARGUMENTS = 'commandArguments',

  CONTAINER_GATEWAY_ENABLED = 'containerGatewayEnabled',
  CONTAINER_GATEWAY_PORT = 'containerGatewayPort',
  CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION = 'containerGatewayRequiresAuthentication',
  CONTAINER_GATEWAY_LOAD_BALANCER = 'containerGatewayLoadBalancer',
  CONTAINER_GATEWAY_LIMIT_SERVER = 'containerGatewayLimitServer',
  CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT = 'containerGatewayClientRequestTimeout',
  CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT = 'containerGatewayServerResponseTimeout',

  EXTERNAL_LOGGING_SERVICE = 'externalLoggingService',
  AXIOM_HOST = 'axiomHost',
  AXIOM_DATASET_NAME = 'axiomDatasetName',
  AXIOM_API_KEY = 'axiomApiKey',
  DATADOG_API = 'datadogApi',
  DATADOG_HOST = 'datadogHost',
  DATADOG_TAGS = 'datadogTags',
  HTTP_COMPRESSION = 'httpCompression',
  HTTP_FORMAT = 'httpFormat',
  HTTP_HEADERS = 'httpHeaders',
  HTTP_HOST = 'httpHost',
  HTTP_PASSWORD = 'httpPassword',
  HTTP_PATH = 'httpPath',
  HTTP_PORT = 'httpPort',
  HTTP_USER = 'httpUser',
  NEW_RELIC_HOST = 'newRelicHost',
  NEW_RELIC_INGESTION_KEY = 'newRelicIngestionKey',
  SPLUNK_HOST = 'splunkHost',
  SPLUNK_TOKEN = 'splunkToken',
  TCP_HOST = 'tcpHost',
  TCP_PORT = 'tcpPort',

  ENVIRONMENT_VARIABLES = 'environmentVariables',

  JOB_QUEUE = 'jobQueue',
  JOB_QUEUE_PATH = 'jobQueuePath',
  JOB_QUEUE_PORT = 'jobQueuePort',

  AUTOSCALING_ENABLED = 'autoscalingEnabled',
  AUTOSCALING_DESIRED_QUEUE_LENGTH = 'autoscalingDesiredQueueLength',
  AUTOSCALING_MINIMUM_REPLICAS = 'autoscalingMinimumReplicas',
  AUTOSCALING_MAXIMUM_REPLICAS = 'autoscalingMaximumReplicas',
  AUTOSCALING_PERIOD = 'autoscalingPeriod',
  AUTOSCALING_MAXIMUM_UPSCALE_PER_MINUTE = 'autoscalingMaximumUpscalePerMinute',
  AUTOSCALING_MAXIMUM_DOWNSCALE_PER_MINUTE = 'autoscalingMaximumDownscalePerMinute',

  // AUTOSTART_POLICY = 'autostartPolicy',
}
