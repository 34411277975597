import { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../../../layouts/threeColumnLayout'
import { CreateOrganizationWizardPageSteps, CreditSettingsFormValues } from '../../models'
import { CreateOrganizationWizardProgressBar } from '../CreateOrganizationWizardProgressBar.tsx'
import { ConfigureCreditSettings } from './components/ConfigureCreditSettings'

export interface ConfigureCreditSettingsPageProps {
  /** The default values for the credit settings form. */
  creditSettingsDefaultValues?: CreditSettingsFormValues
  /** The flag indicating that the request to configure credit settings is pending. */
  isConfigureCreditSettingsPending: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating that the request to skip to the free trial page is pending. */
  isSkipToFreeTrialPending: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed configures the credit settings */
  onConfigureCreditSettings: (values: CreditSettingsFormValues) => void
  /** A callback that when executed navigates to the free trial page. */
  onSkipToFreeTrial: () => void
}

export const ConfigureCreditSettingsPage: FunctionComponent<ConfigureCreditSettingsPageProps> = ({
  creditSettingsDefaultValues,
  isConfigureCreditSettingsPending,
  isLeftColumnOpen,
  isSkipToFreeTrialPending,
  onBack,
  onCloseLeftDrawer,
  onConfigureCreditSettings,
  onSkipToFreeTrial,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar currentPage={CreateOrganizationWizardPageSteps.ConfigureCreditSettings} />
      }
      MainColumn={
        <ConfigureCreditSettings
          creditSettingsDefaultValues={creditSettingsDefaultValues}
          isConfigureCreditSettingsPending={isConfigureCreditSettingsPending}
          isSkipToFreeTrialPending={isSkipToFreeTrialPending}
          onBack={onBack}
          onConfigureCreditSettings={onConfigureCreditSettings}
          onSkipToFreeTrial={onSkipToFreeTrial}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      isMainColumnWithoutScrollStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
