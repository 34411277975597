import { defineMessages } from 'react-intl'

export const OrganizationInformationMessages = defineMessages({
  continueLabel: {
    defaultMessage: 'Continue',
    id: 'Fx/3D0UA',
    description: 'The label for the continue button.',
  },
  description: {
    defaultMessage:
      'Please type in a {permanent_name} for your organization. This will be used in API calls. You can change display names shown in the portal later.',
    id: 'TUA0W8uH',
    description: 'The description about what creating an organization means.',
  },
  descriptionPermanentNameText: {
    defaultMessage: 'permanent name',
    id: '5Hk63+uH',
    description: 'The text for the permanent name.',
  },
  organizationDetailsDescription: {
    defaultMessage: 'Organizations allow you to share access to projects with other users.',
    id: 'J2FJdIv8',
    description: 'The description for the organization input fields section of the form.',
  },
  organizationDetailsHeader: {
    defaultMessage: 'Organization Details',
    id: 'X2vJrNmA',
    description: 'The header for the organization input fields section of the form.',
  },
  organizationNameAvailable: {
    defaultMessage: 'The organization name {organization_name} is available.',
    id: 'N4HI6cyx',
    description: 'The message for when the organization name is available.',
  },
  organizationNameFieldLabel: {
    defaultMessage: 'Organization Slug Name',
    id: 'Sopn68hK',
    description: 'The label for the organization name field.',
  },
  organizationNameHelperText: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen.',
    id: 'k8H+k/sZ',
    description: 'The helper text for filling out the organization name input field.',
  },
  organizationNameTakenErrorText: {
    defaultMessage: 'The organization name {organization_name} is already taken. Please choose another name.',
    id: 'GpCT57BD',
    description: 'The error text for when the organization name is already taken',
  },
  title: {
    defaultMessage: 'Create a New Organization',
    id: 'Ykufmjjy',
    description: 'The title for the Organization Information Page.',
  },
})
