import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../../components/block/HelpScoutSupportLink'
import type { ToastNotificationType } from '../../models'
import { turnstileInconsistentUserAgentMessages } from './messages'

/**
 * Retrieves the content needed for a Turnstile inconsistent user-agent error toast notification.
 *
 * @param errorCode The specific error code returned by the Turnstile service.
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getTurnstileInconsistentUserAgentErrorContent = (
  errorCode: string,
  intl: IntlShape,
): { body: ReactNode; type: ToastNotificationType; title: string } => {
  return {
    body: intl.formatMessage(turnstileInconsistentUserAgentMessages.bodyText, {
      error_code: errorCode,
      contact_support: (
        <HelpScoutSupportLink
          linkedText={intl.formatMessage(turnstileInconsistentUserAgentMessages.contactSupportLinkText)}
          classNames="text-blue-90 underline"
        />
      ),
    }),
    type: 'error',
    title: intl.formatMessage(turnstileInconsistentUserAgentMessages.turnstileErrorTitle),
  }
}
