import { defineMessages } from 'react-intl'

export const CreateOrganizationWizardPageMessages = defineMessages({
  addPaymentMethodLabel: {
    defaultMessage: 'Add Payment Method',
    id: 'pBpo3oSm',
    description: 'The label for the add payment method page.',
  },
  configureCreditSettingsLabel: {
    defaultMessage: 'Configure Credit Settings',
    id: 's7Azh0MK',
    description: 'The label for the configure credit settings page.',
  },
  inviteTeamMembersLabel: {
    defaultMessage: 'Invite Team Members',
    id: '8WQqO+Qt',
    description: 'The label for the invite team members page.',
  },
  organizationInformationLabel: {
    defaultMessage: 'Organization Information',
    id: 'ebeUZbBU',
    description: 'The label for the organization information page.',
  },
  paymentSummaryLabel: {
    defaultMessage: 'Payment Summary',
    id: 'y1rYwyVB',
    description: 'The label for the payment summary page.',
  },
})
