import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Link } from '../../../../../../components/base'
import { Spinner } from '../../../../../../components/Spinner'
import { AddPaymentMethodMessages } from '../../../../messages'
import { StripeFormWrapper } from '../StripeFormWrapper'

export interface AddPaymentMethodProps {
  /** Client secret for Stripe setup intent */
  clientSecret: string | undefined
  /** A flag indicating if the user has selected the free trial path */
  isFreeTrial?: boolean
  /** Indicates if payment is in progress */
  isGetAddPaymentMethodSetupStatusPending: boolean
  /** Flag to indicate if this component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** The callback handler for when the user clicks the back button */
  onBack: () => void
  /** Callback for when the form submission error occurs */
  onSubmitStripeFormError: (message?: string, status?: string) => void
  /** Callback for when the form submission succeeds */
  onSubmitStripeFormSucceeded: () => void
}

export const AddPaymentMethod: FunctionComponent<AddPaymentMethodProps> = ({
  clientSecret,
  isFreeTrial,
  isGetAddPaymentMethodSetupStatusPending,
  isStorybookRender,
  onBack,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  const intl = useIntl()

  return (
    <div className="relative h-[calc(100vh-4rem)] w-full overflow-y-auto">
      <div className="px-6 pt-8">
        <div className="w-full max-w-2xl">
          <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(AddPaymentMethodMessages.title)}</h1>
          <p className="mb-10 text-base">
            {isFreeTrial
              ? intl.formatMessage(AddPaymentMethodMessages.freeTrialDescription, {
                  see_our_billing_terms_link: (
                    <Link url="https://salad.com/terms/saladcloud">
                      {intl.formatMessage(AddPaymentMethodMessages.billingTermsLink)}
                    </Link>
                  ),
                })
              : intl.formatMessage(AddPaymentMethodMessages.nonFreeTrialDescription, {
                  billing_terms_link: (
                    <Link url="https://salad.com/terms/saladcloud">
                      {intl.formatMessage(AddPaymentMethodMessages.seeOurBillingTermsLink)}
                    </Link>
                  ),
                })}
          </p>
          {!clientSecret ? (
            <div className="flex justify-center align-middle">
              <Spinner size="lg" />
            </div>
          ) : (
            <div className="mb-4">
              <StripeFormWrapper
                clientSecret={clientSecret}
                isGetAddPaymentMethodSetupStatusPending={isGetAddPaymentMethodSetupStatusPending}
                isStorybookRender={isStorybookRender}
                onBack={onBack}
                onSubmitStripeFormError={onSubmitStripeFormError}
                onSubmitStripeFormSucceeded={onSubmitStripeFormSucceeded}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
