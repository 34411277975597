import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../../components/block/HelpScoutSupportLink'
import type { ToastNotificationType } from '../../models'
import { createOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for a general error `ToastNotification` that is shown when the user tries to configure
 * an invalid initial credit in the organization wizard.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getCreateOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(
      createOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContentMessages.body,
      {
        contact_support: (
          <HelpScoutSupportLink
            linkedText={intl.formatMessage(
              createOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContentMessages.contactSupportLinkText,
            )}
            classNames="text-red-70 underline"
          />
        ),
      },
    ),
    type: 'error',
    title: intl.formatMessage(
      createOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContentMessages.title,
    ),
  }
}
