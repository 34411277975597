import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { InvitationList } from '@saladtechnologies/openapi-cloud-portal-browser'
import { RequestStatus } from '../../models'
import type {
  MyOrganization,
  MyOrganizationInvitation,
  MyOrganizationInvitationsList,
  MyOrganizationsList,
  OrganizationWithMembersAndInvitationsResponse,
  SwitchToOrganizationPath,
} from './models'

interface MyOrganizationsState {
  myOrganizationsList: MyOrganizationsList
  myOrganizationInvitationsList: MyOrganizationInvitationsList
}

const initialState: MyOrganizationsState = {
  myOrganizationsList: [],
  myOrganizationInvitationsList: [],
}

export const myOrganizationsSlice = createSlice({
  name: 'myOrganizations',
  initialState,
  reducers: {
    acceptInvitation(_state, _action: PayloadAction<{ invitationId: string; organizationDisplayName: string }>) {},
    getOrganizationsAndOrganizationInvitations() {},
    getOrganizationsPageData() {},
    declineInvitation(_state, _action: PayloadAction<{ invitationId: string; organizationDisplayName: string }>) {},
    setAcceptInvitationRequestStatus(state, action: PayloadAction<{ invitationId: string; status: RequestStatus }>) {
      if (action.payload.status === RequestStatus.Succeeded) {
        state.myOrganizationInvitationsList = state.myOrganizationInvitationsList.map((invitation) => {
          if (invitation.invitation !== undefined && invitation.invitation.id === action.payload.invitationId) {
            invitation.acceptInvitationRequestStatus = RequestStatus.Succeeded
          }

          return invitation
        })
      } else {
        state.myOrganizationInvitationsList = state.myOrganizationInvitationsList.map((invitation) => {
          if (invitation.invitation !== undefined && invitation.invitation.id === action.payload.invitationId) {
            invitation.acceptInvitationRequestStatus = action.payload.status
          }

          return invitation
        })
      }
    },
    setMyOrganizationsList(
      state,
      action: PayloadAction<{
        myOrganizationsResponse: OrganizationWithMembersAndInvitationsResponse[]
        switchToOrgPath: SwitchToOrganizationPath
        currentUserEmail: string | undefined
      }>,
    ) {
      const myOrganizationsResponseCopy = [...action.payload.myOrganizationsResponse]
      const myOrganizations: MyOrganizationsList = myOrganizationsResponseCopy.map((response) => {
        const myOrganization: MyOrganization = {
          createdDate: response.organization.createTime,
          displayName: response.organization.displayName,
          id: response.organization.id,
          memberCount: response.memberCount,
          name: response.organization.name,
          projectCount: response.projectCount,
          switchToOrgPath: action.payload.switchToOrgPath[response.organization.name] as string,
        }
        return myOrganization
      })

      state.myOrganizationsList = [...myOrganizations]
    },
    setInvitationList(state, action: PayloadAction<{ invitations: InvitationList }>) {
      const myInvitationsReponseCopy = [...action.payload.invitations.items]

      const myInvitations: MyOrganizationInvitation[] = myInvitationsReponseCopy.map((invite) => {
        const myInvitation: MyOrganizationInvitation = {
          acceptInvitationRequestStatus: RequestStatus.Idle,
          declineInvitationRequestStatus: RequestStatus.Idle,
          invitation: invite,
        }
        return myInvitation
      })
      state.myOrganizationInvitationsList = myInvitations
    },
    setRejectInvitationRequestStatus(state, action: PayloadAction<{ invitationId: string; status: RequestStatus }>) {
      if (action.payload.status === RequestStatus.Succeeded) {
        const filteredInvitations = state.myOrganizationInvitationsList.filter((invitation) => {
          if (invitation.invitation) {
            return invitation.invitation.id !== action.payload.invitationId
          }

          return true
        })
        state.myOrganizationInvitationsList = filteredInvitations
      } else {
        state.myOrganizationInvitationsList = state.myOrganizationInvitationsList.map((invitation) => {
          if (invitation.invitation !== undefined && invitation.invitation.id === action.payload.invitationId) {
            invitation.declineInvitationRequestStatus = action.payload.status
          }

          return invitation
        })
      }
    },
  },
})

export const {
  acceptInvitation,
  declineInvitation,
  getOrganizationsAndOrganizationInvitations,
  getOrganizationsPageData,
  setAcceptInvitationRequestStatus,
  setInvitationList,
  setMyOrganizationsList,
  setRejectInvitationRequestStatus,
} = myOrganizationsSlice.actions
