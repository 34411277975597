import { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../../../layouts/threeColumnLayout'
import { CreateOrganizationWizardPageSteps } from '../../models'
import { CreateOrganizationWizardProgressBar } from '../CreateOrganizationWizardProgressBar.tsx'
import { AddPaymentMethod } from './components/AddPaymentMethod'

export interface AddPaymentMethodPageProps {
  /** Client secret for Stripe setup intent */
  clientSecret: string | undefined
  /** A flag indicating if the user has selected the free trial path */
  isFreeTrial?: boolean
  /** A flag indicating if payment is in progress */
  isGetAddPaymentMethodSetupStatusPending: boolean
  /** A flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** A flag indicating if the component is being rendered in Storybook */
  isStorybookRender?: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback for handling form submission success */
  onSubmitStripeFormSucceeded: () => void
  /** A callback for handling form submission error */
  onSubmitStripeFormError: (message?: string, status?: string) => void
}

export const AddPaymentMethodPage: FunctionComponent<AddPaymentMethodPageProps> = ({
  clientSecret,
  isFreeTrial,
  isGetAddPaymentMethodSetupStatusPending,
  isLeftColumnOpen,
  isStorybookRender,
  onBack,
  onCloseLeftDrawer,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  return (
    <ThreeColumnLayout
      LeftColumn={
        <CreateOrganizationWizardProgressBar
          currentPage={CreateOrganizationWizardPageSteps.AddPaymentMethod}
          isFreeTrial={isFreeTrial}
        />
      }
      MainColumn={
        <AddPaymentMethod
          clientSecret={clientSecret}
          isFreeTrial={isFreeTrial}
          isGetAddPaymentMethodSetupStatusPending={isGetAddPaymentMethodSetupStatusPending}
          isStorybookRender={isStorybookRender}
          onBack={onBack}
          onSubmitStripeFormError={onSubmitStripeFormError}
          onSubmitStripeFormSucceeded={onSubmitStripeFormSucceeded}
        />
      }
      isLeftColumnOpen={isLeftColumnOpen}
      isMainColumnWithoutLayoutStyles
      isMainColumnWithoutScrollStyles
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
