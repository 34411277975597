import type { CreateOrganizationWizardState } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { MyOrganizationInvitationsList, MyOrganizationsList } from '../../features/myOrganizations/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { NoOrganizationsAvailable } from './components/NoOrganizationsAvailable/NoOrganizationsAvailable'
import { OrganizationsMainContent } from './components/OrganizationsMainContent/OrganizationsMainContent'

export interface OrganizationsPageProps {
  /** The list of create organization wizards with reserved names. */
  createOrganizationWizardListWithReservedNames: CreateOrganizationWizardState[]
  /** The flag indicating if the create organization wizard feature flag is enabled. */
  isOrganizationOnboardingWizardFeatureFlagEnabled?: boolean
  /** The flag indicating if a request to delete a create organization wizard is pending. */
  isDeleteCreateOrganizationWizardPending: boolean
  /** The flag indicating if a request to delete a create organization wizard is successful. */
  isDeleteCreateOrganizationWizardSuccessful: boolean
  /** The flag indicating if a request to delete an organization is pending. */
  isDeleteOrganizationPending: boolean
  /** The flag indicating if a request to delete an organization is successful. */
  isDeleteOrganizationSuccessful: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /**
   * The callback executed when the user accepts an invitation.
   *
   * @param invitationId The id of the invitation to accept.
   */
  onAcceptInvitation: (invitationId: string) => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback that starts the create organization wizard flow. */
  onCreateOrganizationWizard: () => void
  /**
   * The callback executed when the user declines an invitation.
   *
   * @param invitationId The id of the invitation to decline
   */
  onDeclineInvitation: (invitationId: string) => void
  /**
   * The callback executed when the user deletes a create organization wizard.
   *
   * @param createOrganizationWizardId The id of the create organization wizard that is being deleted.
   * @param createOrganizationWizardReservedName The reserved name of the create organization wizard that is being
   *   deleted.
   */
  onDeleteCreateOrganizationWizard: (
    createOrganizationWizardId: string,
    createOrganizationWizardReservedName: string,
  ) => void
  /**
   * The callback executed when the user deletes an organization.
   *
   * @param organizationName The name of the organization that is being deleted.
   */
  onDeleteOrganization: (organizationName: string) => void
  /** The list of a user's organizations. */
  organizations: MyOrganizationsList
  /** The list of a user's organization invitations. */
  organizationInvitations: MyOrganizationInvitationsList
}

export const OrganizationsPage: FunctionComponent<OrganizationsPageProps> = ({
  createOrganizationWizardListWithReservedNames,
  isDeleteCreateOrganizationWizardPending,
  isDeleteCreateOrganizationWizardSuccessful,
  isDeleteOrganizationPending,
  isDeleteOrganizationSuccessful,
  isLeftColumnOpen,
  isOrganizationOnboardingWizardFeatureFlagEnabled,
  onAcceptInvitation,
  onCloseLeftDrawer,
  onCreateOrganizationWizard,
  onDeclineInvitation,
  onDeleteCreateOrganizationWizard,
  onDeleteOrganization,
  organizationInvitations,
  organizations,
}) => {
  return (
    <ThreeColumnLayout
      isRightColumnShowcase
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        organizations.length > 0 ||
        organizationInvitations.length > 0 ||
        createOrganizationWizardListWithReservedNames.length > 0 ? (
          <OrganizationsMainContent
            createOrganizationWizardListWithReservedNames={createOrganizationWizardListWithReservedNames}
            isDeleteCreateOrganizationWizardPending={isDeleteCreateOrganizationWizardPending}
            isDeleteCreateOrganizationWizardSuccessful={isDeleteCreateOrganizationWizardSuccessful}
            isDeleteOrganizationPending={isDeleteOrganizationPending}
            isDeleteOrganizationSuccessful={isDeleteOrganizationSuccessful}
            isOrganizationOnboardingWizardFeatureFlagEnabled={isOrganizationOnboardingWizardFeatureFlagEnabled}
            onAcceptInvitation={(invitationId) => onAcceptInvitation(invitationId)}
            onCreateOrganizationWizard={onCreateOrganizationWizard}
            onDeclineInvitation={(invitationId) => onDeclineInvitation(invitationId)}
            onDeleteCreateOrganizationWizard={(createOrganizationWizardId, createOrganizationWizardReservedName) =>
              onDeleteCreateOrganizationWizard(createOrganizationWizardId, createOrganizationWizardReservedName)
            }
            onDeleteOrganization={onDeleteOrganization}
            organizations={organizations}
            organizationInvitations={organizationInvitations}
          />
        ) : (
          <NoOrganizationsAvailable
            onCreateOrganizationWizard={onCreateOrganizationWizard}
            isOrganizationOnboardingWizardFeatureFlagEnabled={isOrganizationOnboardingWizardFeatureFlagEnabled}
          />
        )
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
