import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { LoadStripeErrorMessages } from './messages'

/**
 * Returns the content for a toast notification when a Stripe error occurs.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getLoadStripeErrorMessage = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: (
      <p>
        {intl.formatMessage(LoadStripeErrorMessages.bodyText)}
        <span className="text-blue-90 underline" onClick={() => window.location.reload()}>
          {intl.formatMessage(LoadStripeErrorMessages.refreshThePageLinkText)}
        </span>
      </p>
    ),
    title: intl.formatMessage(LoadStripeErrorMessages.stripeErrorTitle),
    type: 'error',
  }
}
