import { defineMessages } from 'react-intl'

export const createOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'There was an error saving your initial credit. Try again and if this issue persists {contact_support}.',
    id: 'bu/BUwJD',
    description:
      'The description shown in a toast notification message when we encounter an error on trying to configure the initial credit.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'K3W/SF6o',
    description:
      'The text shown for the contact support link in the configure credit settings error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to configure initial credit',
    id: 'biPo9KgS',
    description:
      'The toast notification title shown when we encounter an error on trying to configure the initial credit.',
  },
})
