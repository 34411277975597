import { defineMessages } from 'react-intl'

export const createOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeAmountErrorContentMessages =
  defineMessages({
    body: {
      defaultMessage:
        'There was an error saving your automatic recharge amount. Try again and if this issue persists {contact_support}.',
      id: '7zumtWNV',
      description:
        'The description shown in a toast notification message when we encounter an error on trying to configure the automatic recharge amount.',
    },
    contactSupportLinkText: {
      defaultMessage: 'contact support',
      id: 'K3W/SF6o',
      description:
        'The text shown for the contact support link in the configure credit settings error toast notification.',
    },
    title: {
      defaultMessage: 'Unable to configure automatic recharge amount',
      id: 'UzYFAM73',
      description:
        'The toast notification title shown when we encounter an error on trying to configure the automatic recharge amount.',
    },
  })
