import { defineMessages } from 'react-intl'

export const getCreateOrganizationWizardGeneralErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to load your previous organization information. Try again and if this issue persists {contact_support}.',
    id: 'i0dnsuNm',
    description:
      'The description shown in a toast notification message when we encounter an error on trying to get the createOrganizationWizard',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '7V7CwYJ5',
    description:
      'The text shown for the contact support link in the get createOrganizationWizard error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to load your previous organization information',
    id: '5hXa+IUD',
    description:
      'The toast notification title shown when we encounter an error on trying to get the createOrganizationWizard',
  },
})
