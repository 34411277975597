import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import type { FormEvent, FunctionComponent } from 'react'
import { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../../../components/Button'
import { Spinner } from '../../../../../../components/Spinner'
import { showToastNotification } from '../../../../../../features/notifications/notificationsSlice'
import { getLoadStripeErrorMessage } from '../../../../../../notifications/clientToastNotificationContent/billing/getLoadStripeErrorMessage'
import { AddPaymentMethodMessages } from '../../../../messages'

interface StripeFormProps {
  /** The client secret for Stripe SetupIntent */
  clientSecret?: string
  /** Flag to indicate if the payment method is being added */
  isGetAddPaymentMethodSetupStatusPending?: boolean
  /** Flag to indicate if this is being rendered in Storybook */
  isStorybookRender?: boolean
  /** The callback handler for cancelling the form */
  onBack: () => void
  /** The callback handler for when the Stripe submit form hits an error */
  onSubmitStripeFormError: (message?: string, status?: string) => void
  /** The callback handler for when the Stripe submit form succeeded */
  onSubmitStripeFormSucceeded: () => void
}

export const StripeForm: FunctionComponent<StripeFormProps> = ({
  clientSecret,
  isGetAddPaymentMethodSetupStatusPending,
  isStorybookRender,
  onBack,
  onSubmitStripeFormError,
  onSubmitStripeFormSucceeded,
}) => {
  const intl = useIntl()
  const stripe = useStripe()
  const elements = useElements()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentElementLoading, setIsPaymentElementLoading] = useState(true)
  const [isAddressElementLoading, setIsAddressElementLoading] = useState(true)
  // Using a ref because multiple errors on stripe can happen and we only want to show the first one and not multiple toasts
  const hasErrorRef = useRef(false)

  const handleError = useCallback(
    (error: any) => {
      if (!hasErrorRef.current) {
        showToastNotification(getLoadStripeErrorMessage(intl))
        onSubmitStripeFormError(error.message, error.setup_intent?.status)
        hasErrorRef.current = true
      }
    },
    [onSubmitStripeFormError, intl],
  )

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault()

        if (stripe == null || elements == null) {
          return
        }

        setIsSubmitting(true)

        const url = new URL(window.location.href)
        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: url.toString(),
          },
          redirect: 'if_required',
        })

        if (error) {
          handleError(error)
          setIsSubmitting(false)
        } else {
          if (
            setupIntent.status === 'requires_action' ||
            setupIntent.status === 'requires_confirmation' ||
            setupIntent.status === 'requires_payment_method'
          ) {
            setIsSubmitting(false)
          }
          if (setupIntent.status === 'succeeded') {
            onSubmitStripeFormSucceeded()
            setIsSubmitting(false)
          }
        }
      } catch (error) {
        handleError(error)
        setIsSubmitting(false)
      }
    },
    [stripe, elements, onSubmitStripeFormSucceeded, handleError],
  )

  const handlePaymentElementReady = () => setIsPaymentElementLoading(false)
  const handleAddressElementReady = () => setIsAddressElementLoading(false)

  const isFormLoading = isPaymentElementLoading || isAddressElementLoading

  const navigationButtons = (
    <div className="mt-4 flex justify-between gap-6">
      <Button isFullWidth variant="green-outlined" onClick={onBack}>
        {intl.formatMessage(AddPaymentMethodMessages.backLabel)}
      </Button>
      <Button
        isFullWidth
        variant="green-filled"
        onClick={onSubmitStripeFormSucceeded}
        isLoading={isSubmitting || isGetAddPaymentMethodSetupStatusPending}
        type="submit"
      >
        {intl.formatMessage(AddPaymentMethodMessages.continueLabel)}
      </Button>
    </div>
  )

  if (isStorybookRender && !clientSecret) {
    return (
      <>
        <div className="flex h-60 w-full items-center justify-center bg-neutral-30">
          <span className="text-lg font-medium">{intl.formatMessage(AddPaymentMethodMessages.stripeText)}</span>
        </div>
        {navigationButtons}
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {isFormLoading && (
        <div className="my-10 flex w-full justify-center">
          <Spinner size="lg" />
        </div>
      )}
      <PaymentElement options={{ layout: 'tabs' }} onReady={handlePaymentElementReady} onLoadError={handleError} />
      <AddressElement
        className="my-4"
        options={{ mode: 'billing', fields: { phone: 'never' } }}
        onReady={handleAddressElementReady}
        onLoadError={handleError}
      />
      {navigationButtons}
    </form>
  )
}
