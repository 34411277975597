import { defineMessages } from 'react-intl'

export const PaymentSummaryMessages = defineMessages({
  backLabel: {
    defaultMessage: 'Back',
    id: '8hqghabD',
    description: 'Label for the Back button on the Payment Summary page',
  },
  billingTermsLink: {
    defaultMessage: 'billing terms',
    id: 'NNMgsiSy',
    description: 'Link text for billing terms on the Payment Summary page',
  },
  creditCardExpirationDateLabel: {
    defaultMessage: 'Expires',
    id: '5t76H7Sc',
    description: 'Displays the expiration date of the card',
  },
  cardLast4Label: {
    defaultMessage: '•••• {last_four}',
    id: 'JZldq/Xo',
    description: 'Displays the last four digits of the credit card',
  },
  confirmLabel: {
    defaultMessage: 'Confirm Payment',
    id: 'eN8rIyFM',
    description: 'Label for the Confirm Payment button on the Payment Summary page',
  },
  creditCardFieldText: {
    defaultMessage: 'Credit Card Field',
    id: '9+yQhRZq',
    description: 'Label for the credit card field on the Payment Summary page',
  },
  description: {
    defaultMessage:
      'By continuing you agree to our {billing_terms_link}. Paid credits are non-refundable and expire one year from purchase date.',
    id: 'VhQrM3TP',
    description: 'Description text explaining billing terms and conditions on the Payment Summary page',
  },
  descriptionLabel: {
    defaultMessage: 'Description',
    id: 'ycv1KISy',
    description: 'Label for the description column in the payment summary',
  },
  estimatedTaxLabel: {
    defaultMessage: 'Estimated Tax',
    id: 'MQ3Ii8Ws',
    description: 'Label for the estimated tax row in the payment summary',
  },
  estimatedTotalLabel: {
    defaultMessage: 'Estimated Total',
    id: 'KKU6f+KP',
    description: 'Label for the estimated total row in the payment summary',
  },
  paymentDetails: {
    defaultMessage: 'Your payment details will appear here.',
    id: 'Z2ZHbgK1',
    description: 'Placeholder text for where payment details will be displayed on the Payment Summary page',
  },
  paymentMethodLabel: {
    defaultMessage: 'Payment Method',
    id: 'f+Ur4hnf',
    description: 'Label for the payment method section',
  },
  saladCreditLabel: {
    defaultMessage: 'SaladCloud Technologies Usage Credit',
    id: 'tx/MORAo',
    description: 'Label for the SaladCloud Technologies Usage Credit row in the payment summary',
  },
  title: {
    defaultMessage: 'Payment Summary',
    id: 'By0bBRVB',
    description: 'Title for the Payment Summary page',
  },
  totalLabel: {
    defaultMessage: 'Total',
    id: 'pBb34Yps',
    description: 'Label for the total column in the payment summary',
  },
  updateButtonLabel: {
    defaultMessage: 'Update',
    id: 'cp1w9Asf',
    description: 'Text for the update button in the payment method section',
  },
})
