import type { BillingPaymentMethodSetupIntent } from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  BillingPaymentMethodSetupIntentStatusEnum,
  CreateBillingPortalSessionProblemType,
  ResponseError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { IntlShape } from 'react-intl'
import {
  EMPTY,
  Subject,
  catchError,
  concat,
  delay,
  filter,
  from,
  map,
  mergeMap,
  of,
  repeat,
  retry,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs'
import { BillingAPI, OrganizationsAPI, ProjectsAPI, assertUnreachable } from '../apiMethods'
import {
  createPaymentMethodSetupIntent,
  fetchPaymentMethod,
  getBillingCustomerPortal,
  getBillingPageData,
  getPaymentMethodSetupStatus,
  getPaymentMethodSetupStatusAfterProcessingState,
  removePaymentMethod,
  setPaymentMethodSetupIntent,
  showStripeFormError,
  unsubscribeFromBilling,
} from '../features/billing/billingSlice'
import {
  getBillingCreditsDashboardEmbedUrl,
  getBillingInvoiceDashboardEmbedUrl,
  getBillingUsageDashboardEmbedUrl,
} from '../features/billingDashboards/billingDashboardsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { selectOrganizationHasHadValidPayment } from '../features/organizations/organizationsSelectors'
import { organizationAdded, organizationUpdated } from '../features/organizations/organizationsSlice'
import { paymentMethodAdded, paymentMethodRemoved } from '../features/paymentMethod/paymentMethodSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getAddNewPaymentMethodAndPrepayChargedSuccessfully,
  getAddNewPaymentMethodGenericValidationError,
  getAddNewPaymentMethodSucceeded,
  getGenericStripeFormError,
  getLoadCustomBillingPortalError,
  getLoadCustomBillingPortalNotAuthorizedError,
  getLoadCustomBillingPortalNotReadyError,
  getLoadCustomBillingPortalOrganizationNotFoundError,
  getLoadPaymentMethodError,
  getPaymentMethodNotAllowedValidationError,
  getPaymentMethodVerificationInProgressError,
  getPaymentSessionCanceledError,
  getRemovePaymentMethodError,
} from '../notifications/clientToastNotificationContent/billing'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const fetchPaymentMethodUtil = (organizationName: string, intl: IntlShape) =>
  of(null).pipe(
    switchMap(() =>
      from(BillingAPI.getPaymentMethod({ organizationName })).pipe(map((response) => paymentMethodAdded(response))),
    ),
    retry({
      count: 3,
      delay: (error) => {
        const isPaymentMethodNotFoundErrorType = error instanceof ResponseError && error.response.status === 404
        if (isPaymentMethodNotFoundErrorType) {
          throw error
        }

        return timer(1000)
      },
    }),
    catchError((error) =>
      error instanceof ResponseError && error.response.status === 400
        ? from(error.response.json()).pipe(
            mergeMap((errorResponse) => {
              const errorType = errorResponse.type as string | null
              if (errorType === 'customer_information_not_ready_yet') {
                return EMPTY
              } else {
                return concat(of(showToastNotification(getLoadPaymentMethodError(intl))))
              }
            }),
          )
        : error instanceof ResponseError && error.response.status === 404
          ? concat(of(paymentMethodRemoved(organizationName)))
          : concat(of(showToastNotification(getLoadPaymentMethodError(intl)))),
    ),
  )

export const getPaymentMethodAndSetupIntentStatusUtil = (organizationName: string, shouldStopOnProcessing: boolean) => {
  const endRepeat$ = new Subject()

  return of(null).pipe(
    switchMap(() =>
      from(BillingAPI.getPaymentMethodSetupIntent({ organizationName })).pipe(
        switchMap((paymentMethodSetupIntentResponse) => {
          const billingStatus = paymentMethodSetupIntentResponse.status
          const statusesToStopPolling: BillingPaymentMethodSetupIntentStatusEnum[] = shouldStopOnProcessing
            ? [
                BillingPaymentMethodSetupIntentStatusEnum.Canceled,
                BillingPaymentMethodSetupIntentStatusEnum.Succeeded,
                BillingPaymentMethodSetupIntentStatusEnum.Failed,
                BillingPaymentMethodSetupIntentStatusEnum.Processing,
              ]
            : [
                BillingPaymentMethodSetupIntentStatusEnum.Canceled,
                BillingPaymentMethodSetupIntentStatusEnum.Succeeded,
                BillingPaymentMethodSetupIntentStatusEnum.Failed,
              ]

          const isStatusMetTheConditionToStopPolling = statusesToStopPolling.includes(billingStatus)
          if (isStatusMetTheConditionToStopPolling) {
            endRepeat$.next(null)
          }

          return of(setPaymentMethodSetupIntent({ setupIntent: paymentMethodSetupIntentResponse }))
        }),
      ),
    ),
    repeat({
      delay: (count) => timer(count * 500),
    }),
    // *NOTE: We need `delay` to fire off last action with `setPaymentMethodSetupIntent`
    //        after we met the expected status to stop polling
    takeUntil(endRepeat$.pipe(delay(1))),
  )
}

export const onGetPaymentMethodSetupStatus: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(getPaymentMethodSetupStatus.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getPaymentMethodSetupIntent', status: 'pending' })),
        getPaymentMethodAndSetupIntentStatusUtil(organizationName, true).pipe(
          switchMap((getPaymentMethodAndSetupIntentStatusAction) => {
            const isFirstTimeAddingPaymentMethod =
              selectOrganizationHasHadValidPayment(state$.value, organizationName) === false
            const isBillingStatusProcessing =
              getPaymentMethodAndSetupIntentStatusAction.payload.setupIntent?.status ===
              BillingPaymentMethodSetupIntentStatusEnum.Processing
            const isBillingStatusSucceeded =
              getPaymentMethodAndSetupIntentStatusAction.payload.setupIntent?.status ===
              BillingPaymentMethodSetupIntentStatusEnum.Succeeded
            const isBillingStatusFailed =
              getPaymentMethodAndSetupIntentStatusAction.payload.setupIntent?.status ===
              BillingPaymentMethodSetupIntentStatusEnum.Failed

            let toastNotification
            let fetchCreditsDashboardEmbedUrl = false
            let updateHasHadValidPayment = false

            if (isBillingStatusSucceeded || isBillingStatusProcessing) {
              toastNotification = isFirstTimeAddingPaymentMethod
                ? getAddNewPaymentMethodAndPrepayChargedSuccessfully(intl)
                : getAddNewPaymentMethodSucceeded(intl)
              fetchCreditsDashboardEmbedUrl = true
              updateHasHadValidPayment = isFirstTimeAddingPaymentMethod
            }

            if (isBillingStatusFailed) {
              const billingLastErrorMessage =
                getPaymentMethodAndSetupIntentStatusAction.payload.setupIntent?.lastErrorMessage
              const isAuthenticationRequiredError = billingLastErrorMessage?.includes('requires authentication')

              if (isAuthenticationRequiredError) {
                toastNotification = getPaymentMethodNotAllowedValidationError(intl)
              } else {
                toastNotification = getAddNewPaymentMethodGenericValidationError(intl)
              }
            }

            if (toastNotification) {
              return concat(
                of(getPaymentMethodAndSetupIntentStatusAction, showToastNotification(toastNotification)),
                fetchCreditsDashboardEmbedUrl ? of(getBillingCreditsDashboardEmbedUrl({ organizationName })) : EMPTY,
                updateHasHadValidPayment
                  ? of(organizationUpdated({ id: organizationName, changes: { hasHadValidPayment: true } }))
                  : EMPTY,
                fetchPaymentMethodUtil(organizationName, intl),
                isBillingStatusProcessing
                  ? of(getPaymentMethodSetupStatusAfterProcessingState({ organizationName }))
                  : EMPTY,
              )
            } else {
              return of(getPaymentMethodAndSetupIntentStatusAction)
            }
          }),
          catchError(() => concat(fetchPaymentMethodUtil(organizationName, intl))),
        ),
        of(setRequestStatus({ request: 'getPaymentMethodSetupIntent', status: 'succeeded' })),
        of(setRequestStatus({ request: 'getPaymentMethodSetupIntent', status: 'idle' })).pipe(delay(1)),
      ).pipe(
        catchError(() =>
          concat(
            of(setRequestStatus({ request: 'getPaymentMethodSetupIntent', status: 'failed' })),
            of(setRequestStatus({ request: 'getPaymentMethodSetupIntent', status: 'idle' })).pipe(delay(1)),
          ),
        ),
        takeUntil(action$.pipe(filter(unsubscribeFromBilling.match))),
      ),
    ),
  )

export const onGetPaymentMethodSetupStatusAfterProcessingState: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getPaymentMethodSetupStatusAfterProcessingState.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        getPaymentMethodAndSetupIntentStatusUtil(organizationName, false).pipe(
          switchMap((getPaymentMethodAndSetupIntentStatusAction) => {
            const billingStatus = getPaymentMethodAndSetupIntentStatusAction.payload.setupIntent?.status || ''
            const isBillingStatusSucceeded = billingStatus === BillingPaymentMethodSetupIntentStatusEnum.Succeeded
            const isBillingStatusFailed = billingStatus === BillingPaymentMethodSetupIntentStatusEnum.Failed
            const isBillingStatusCanceled = billingStatus === BillingPaymentMethodSetupIntentStatusEnum.Canceled
            let toastNotification

            if (isBillingStatusFailed) {
              const billingLastErrorMessage =
                getPaymentMethodAndSetupIntentStatusAction.payload.setupIntent?.lastErrorMessage
              const isAuthenticationRequiredError = billingLastErrorMessage?.includes('requires authentication')

              if (isAuthenticationRequiredError) {
                toastNotification = getPaymentMethodNotAllowedValidationError(intl)
              } else {
                toastNotification = getAddNewPaymentMethodGenericValidationError(intl)
              }
            }

            if (isBillingStatusSucceeded || isBillingStatusFailed || isBillingStatusCanceled) {
              return concat(
                of(getPaymentMethodAndSetupIntentStatusAction),
                toastNotification ? of(showToastNotification(toastNotification)) : EMPTY,
                fetchPaymentMethodUtil(organizationName, intl),
              )
            } else {
              return of(getPaymentMethodAndSetupIntentStatusAction)
            }
          }),
          catchError(() => concat(fetchPaymentMethodUtil(organizationName, intl))),
        ),
      ).pipe(
        catchError(() =>
          concat(
            of(setRequestStatus({ request: 'getPaymentMethodSetupIntent', status: 'failed' })),
            of(setRequestStatus({ request: 'getPaymentMethodSetupIntent', status: 'idle' })).pipe(delay(1)),
          ),
        ),
        takeUntil(action$.pipe(filter(unsubscribeFromBilling.match))),
      ),
    ),
  )

export const onStripeFormError: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(showStripeFormError.match),
    switchMap(({ payload: { message, status } }) => {
      if (message === undefined) {
        return EMPTY
      }

      switch (status) {
        case BillingPaymentMethodSetupIntentStatusEnum.Succeeded:
        case BillingPaymentMethodSetupIntentStatusEnum.Processing: {
          return of(showToastNotification(getPaymentMethodVerificationInProgressError(intl)))
        }

        case BillingPaymentMethodSetupIntentStatusEnum.Canceled: {
          return of(showToastNotification(getPaymentSessionCanceledError(intl)))
        }

        default: {
          return of(showToastNotification(getGenericStripeFormError(intl, message)))
        }
      }
    }),
  )

export const onFetchPaymentMethod: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(fetchPaymentMethod.match),
    switchMap((action) => fetchPaymentMethodUtil(action.payload.organizationName, intl)),
  )

export const onGetBillingCustomerPortal: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getBillingCustomerPortal.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'pending' })),
        from(
          BillingAPI.createBillingPortalSession({
            organizationName: action.payload.organizationName,
            createBillingPortalSession: {
              redirectPath: action.payload.redirectPath,
            },
          }),
        ).pipe(
          mergeMap((createBillingPortalSessionResponse) => {
            window.location.href = createBillingPortalSessionResponse.redirectUrl
            return concat(
              of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'succeeded' })),
              of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateBillingPortalSessionProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case CreateBillingPortalSessionProblemType.CustomerInformationNotReadyYet:
                          return concat(
                            of(
                              showToastNotification(getLoadCustomBillingPortalNotReadyError(intl)),
                              setRequestStatus({ request: 'getBillingCustomerPortal', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateBillingPortalSessionProblemType.NotAuthorizedForThisOrganization:
                          return concat(
                            of(
                              showToastNotification(getLoadCustomBillingPortalNotAuthorizedError(intl)),
                              setRequestStatus({ request: 'getBillingCustomerPortal', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateBillingPortalSessionProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification(getLoadCustomBillingPortalOrganizationNotFoundError(intl)),
                              setRequestStatus({ request: 'getBillingCustomerPortal', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getLoadCustomBillingPortalError(intl)),
                          setRequestStatus({ request: 'getBillingCustomerPortal', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getLoadCustomBillingPortalError(intl)),
                    setRequestStatus({ request: 'getBillingCustomerPortal', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'getBillingCustomerPortal', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

export const onRemovePaymentMethod: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(removePaymentMethod.match),
    switchMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'removePaymentMethod', status: 'pending' })),
        from(BillingAPI.deletePaymentMethod({ organizationName })).pipe(
          switchMap(() =>
            concat(of(paymentMethodRemoved(organizationName), setPaymentMethodSetupIntent({ setupIntent: undefined }))),
          ),
          catchError((error: unknown) => {
            if (error instanceof ResponseError && error.response.status === 404) {
              return concat(
                of(
                  paymentMethodRemoved(organizationName),
                  setPaymentMethodSetupIntent({ setupIntent: undefined }),
                  setRequestStatus({ request: 'removePaymentMethod', status: 'failed' }),
                ),
                of(setRequestStatus({ request: 'removePaymentMethod', status: 'idle' })).pipe(delay(1)),
              )
            }

            return of(showToastNotification(getRemovePaymentMethodError(intl)))
          }),
        ),
        of(setRequestStatus({ request: 'removePaymentMethod', status: 'succeeded' })),
        of(setRequestStatus({ request: 'removePaymentMethod', status: 'idle' })).pipe(delay(1)),
      ),
    ),
  )

export const onCreatePaymentMethodSetupIntent: AppEpic = (action$) =>
  action$.pipe(
    filter(createPaymentMethodSetupIntent.match),
    switchMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'createPaymentMethodSetupIntent', status: 'pending' })),
        from(BillingAPI.createPaymentMethodSetupIntent({ organizationName })).pipe(
          switchMap((paymentMethodSetupIntentResponse) => {
            return concat(
              of(
                setPaymentMethodSetupIntent({ setupIntent: paymentMethodSetupIntentResponse }),
                setRequestStatus({ request: 'createPaymentMethodSetupIntent', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'createPaymentMethodSetupIntent', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((_error) => {
            // TODO: Do we need error handling?
            return concat(
              of(setRequestStatus({ request: 'createPaymentMethodSetupIntent', status: 'failed' })),
              of(setRequestStatus({ request: 'createPaymentMethodSetupIntent', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )

export const showExistingFailedToastNotificationOnBillingPageLoadUtil = (
  setupIntent: BillingPaymentMethodSetupIntent | undefined,
  intl: IntlShape,
) =>
  of(null).pipe(
    switchMap(() => {
      let toastNotification

      if (setupIntent?.status === BillingPaymentMethodSetupIntentStatusEnum.Failed) {
        const billingLastErrorMessage = setupIntent?.lastErrorMessage
        const isAuthenticationRequiredError = billingLastErrorMessage?.includes('requires authentication')

        if (isAuthenticationRequiredError) {
          toastNotification = getPaymentMethodNotAllowedValidationError(intl)
        } else {
          toastNotification = getAddNewPaymentMethodGenericValidationError(intl)
        }
        return of(showToastNotification(toastNotification))
      }

      return EMPTY
    }),
  )

export const onGetBillingPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getBillingPageData.match),
    switchMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getBillingPageData', status: 'pending' })),
        from(
          Promise.allSettled([
            OrganizationsAPI.getOrganization({
              organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName,
            }),
            BillingAPI.getPaymentMethodSetupIntent({ organizationName }),
          ]),
        ).pipe(
          mergeMap(([organizationResponse, projectsResponse, paymentMethodSetupIntentResponse]) => {
            const hasDataRequiredForPage =
              organizationResponse.status === 'fulfilled' && projectsResponse.status === 'fulfilled'

            const setupIntent =
              paymentMethodSetupIntentResponse.status === 'fulfilled'
                ? paymentMethodSetupIntentResponse.value
                : undefined

            const isBillingStatusProcessing =
              setupIntent?.status === BillingPaymentMethodSetupIntentStatusEnum.Processing
            const continuePollingForBillingStatusIfStateProcessing = isBillingStatusProcessing
              ? of(getPaymentMethodSetupStatusAfterProcessingState({ organizationName }))
              : EMPTY
            if (hasDataRequiredForPage) {
              return concat(
                of(
                  organizationAdded(organizationResponse.value),
                  projectsAddedToOrganization({ organizationName, projects: projectsResponse.value.items }),
                  setPaymentMethodSetupIntent({
                    setupIntent,
                  }),
                  getBillingInvoiceDashboardEmbedUrl({ organizationName }),
                  getBillingCreditsDashboardEmbedUrl({ organizationName }),
                  getBillingUsageDashboardEmbedUrl({ organizationName }),
                ),
                fetchPaymentMethodUtil(organizationName, intl),
                showExistingFailedToastNotificationOnBillingPageLoadUtil(setupIntent, intl),
                continuePollingForBillingStatusIfStateProcessing,
                of(setRequestStatus({ request: 'getBillingPageData', status: 'succeeded' })),
                of(setRequestStatus({ request: 'getBillingPageData', status: 'idle' })).pipe(delay(1)),
              )
            } else {
              return concat(
                of(
                  setRequestStatus({ request: 'getBillingPageData', status: 'failed' }),
                  showToastNotification(getLoadCustomBillingPortalError(intl)),
                  navigateTo({ path: '/organizations' }),
                ),
                of(setRequestStatus({ request: 'getBillingPageData', status: 'idle' })).pipe(delay(1)),
              )
            }
          }),
        ),
      ),
    ),
  )
