import { defineMessages } from 'react-intl'

export const EditContainerGatewayFieldsMessages = defineMessages({
  clientRequestTimeoutLabel: {
    defaultMessage: 'Client Request Timeout',
    id: 'UEbF7UC+',
    description: 'The label for the client request timeout field.',
  },
  description: {
    defaultMessage:
      'Container Gateway allows containers to receive external requests. You cannot edit the configuration of your Container Gateway on an existing container group. You must duplicate your container to adjust your settings. For more information on using Container Gateway, see our {documentation_link}.',
    id: 'c8En10Ep',
    description: 'The description for the container group Container Gateway fields.',
  },
  descriptionDocumentationLinkText: {
    defaultMessage: 'documentation here',
    id: 'bsQHkznd',
    description: 'The description documentation link text for the container group Container Gateway fields.',
  },
  enableContainerGatewayLabel: {
    defaultMessage: 'Enable Container Gateway. I’ve set up my container to support IPv6.',
    id: 'kstBbEUj',
    description: 'The label for the enable Container Gateway field.',
  },
  containerGatewayAuthenticationLabel: {
    defaultMessage: 'Use Authentication?',
    id: 'WY6Nn72K',
    description: 'The label for the Container Gateway authentication field.',
  },
  containerGatewayAuthenticationHelperText: {
    defaultMessage: 'When on, requests to containers must be authenticated using your API key',
    id: 'Z+PaTzt6',
    description: 'The helper text for the Container Gateway authentication field.',
  },
  containerGatewayFieldsLabel: {
    defaultMessage: 'Container Gateway',
    id: 'fGEB10In',
    description: 'The label for the container group Container Gateway fields.',
  },
  leastNumberOfConnectionsLabel: {
    defaultMessage: 'Least Number Of Connections',
    id: 'SQIXZzOA',
    description: 'The label for the least connections option in the container gateway load balancing field.',
  },
  limitEachServerLabel: {
    defaultMessage: 'Limit each server to a single, active request.',
    id: '3ZAJfnru',
    description: 'The label for the limit each server option in the container gateway load balancing field.',
  },
  loadBalancerAlgorithmLabel: {
    defaultMessage: 'Load Balancer Algorithm',
    id: 'ge4Mc6fa',
    description: 'The label for the container gateway load balancing field.',
  },
  noLabel: {
    defaultMessage: 'No',
    id: 'XbmUeqha',
    description: 'The label for the no option in the Container Gateway authentication field.',
  },
  portHelperText: {
    defaultMessage: 'Must be a number between 1 to 65535',
    id: 'HXDTVVJn',
    description: 'The helper text for the neworking port input field.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'H1yLuYoS',
    description: 'The label for the neworking port input field.',
  },
  roundRobinLabel: {
    defaultMessage: 'Round Robin',
    id: '+09A1CqT',
    description: 'The label for the round robin option in the container gateway load balancing field.',
  },
  sectionTitle: {
    defaultMessage: 'Container Gateway',
    id: '6Sh4dxaS',
    description:
      'The title for the dialog box that opens when a user wants to edit Container Gateway fields on the create container group page.',
  },
  serverResponseTimeoutLabel: {
    defaultMessage: 'Server Response Timeout',
    id: 'q9Rkwvkw',
    description: 'The label for the server response timeout field.',
  },
  yesLabel: {
    defaultMessage: 'Yes',
    id: 'SJO9UZqf',
    description: 'The label for the yes option in the Container Gateway authentication field.',
  },
})
