// Create Organization Wizard Pages
export const getCreateOrganizationWizardOrganizationInformationPagePath = (createOrganizationWizardId: string) =>
  `/create-organization-wizard/${createOrganizationWizardId}/organization-information`
export const getCreateOrganizationWizardConfigureCreditSettingsPagePath = (createOrganizationWizardId: string) =>
  `/create-organization-wizard/${createOrganizationWizardId}/configure-credit-settings`
export const getCreateOrganizationWizardAddPaymentMethodPagePath = (createOrganizationWizardId: string) =>
  `/create-organization-wizard/${createOrganizationWizardId}/add-payment-method`
export const getCreateOrganizationWizardPaymentMethodSummaryPagePath = (createOrganizationWizardId: string) =>
  `/create-organization-wizard/${createOrganizationWizardId}/payment-method-summary`
export const getCreateOrganizationWizardInviteTeamMembersPagePath = (createOrganizationWizardId: string) =>
  `/create-organization-wizard/${createOrganizationWizardId}/invite-team-members`

// Organization Pages
export const getOrganizationsPagePath = () => `/organizations`

export const getCreateOrganizationsPagePath = () => `/organizations/new`

export const getOrganizationBillingPagePath = (organizationName: string) => `/organizations/${organizationName}/billing`

export const getOrganizationEditPagePath = (organizationName: string) => `/organizations/${organizationName}/edit`

export const getOrganizationWebhookSecretKeyPagePath = (organizationName: string) =>
  `/organizations/${organizationName}/webhook-secret-key`

export const getOrganizationTeamPagePath = (organizationName: string) => `/organizations/${organizationName}/team`

export const getInviteTeamMemberPagePath = (organizationName: string) =>
  `/organizations/${organizationName}/team/invite`

// Inference Endpoint Pages
export const getInferenceEndpointsMarketplacePagePath = (organizationName: string) =>
  `/organizations/${organizationName}/inference-endpoints`

export const getInferenceEndpointDetailsPagePath = (organizationName: string, inferenceEndpointName: string) =>
  `/organizations/${organizationName}/inference-endpoints/${inferenceEndpointName}`

// Project Pages
export const getCreateProjectPagePath = (organizationName: string) => `/organizations/${organizationName}/projects/new`

export const getEditProjectPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/edit`

export const getSelectProductPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}`

export const getNoProjectsPagePath = (organizationName: string) => `/organizations/${organizationName}/no-projects`

// Container Group Pages
export const getContainerGroupDetailsPagePath = (
  organizationName: string,
  projectName: string,
  containerGroupName: string,
) => `/organizations/${organizationName}/projects/${projectName}/containers/${containerGroupName}`

export const getInstancePath = (pageDetailPath: string, instanceId: string) =>
  `${pageDetailPath}/instances/${instanceId}`

export const getContainerGroupsPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/containers`

export const getCreateContainerGroupPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/containers/new`

export const getEditContainerGroupPagePath = (
  organizationName: string,
  projectName: string,
  containerGroupName: string,
) => `/organizations/${organizationName}/projects/${projectName}/containers/${containerGroupName}/edit`

// Job Queue Pages
export const getCreateJobQueuePagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues/new`

export const getEditJobQueuePagePath = (organizationName: string, projectName: string, jobQueueName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues/${jobQueueName}/edit`

export const getJobQueueDetailsPagePath = (organizationName: string, projectName: string, jobQueueName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues/${jobQueueName}`

export const getJobQueuesPagePath = (organizationName: string, projectName: string) =>
  `/organizations/${organizationName}/projects/${projectName}/job-queues`
