import { createSelector } from '@reduxjs/toolkit'
import { CreateOrganizationWizardSetupIntent } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { AppState } from '../../store'
import { createOrganizationWizardAdapter, CreateOrganizationWizardProps } from './createOrganizationWizardSlice'

const createOrganizationWizardSelectors = createOrganizationWizardAdapter.getSelectors()

export const selectCreateOrganizationWizard = (
  state: AppState,
  id: string,
): CreateOrganizationWizardProps | undefined => state.createOrganizationWizard.entities[id]

export const selectCreateOrganizationWizardSetupIntent = (
  state: AppState,
  id: string,
): CreateOrganizationWizardSetupIntent | undefined => {
  const wizard = state.createOrganizationWizard.entities[id]
  return wizard?.createOrganizationWizardSetupIntent
}

export const selectCreateOrganizationWizardListWithReservedNames = createSelector(
  createOrganizationWizardSelectors.selectAll,
  (createOrganizationWizards) =>
    createOrganizationWizards
      .filter((wizard) => wizard.createOrganizationWizard.organizationReservedName !== undefined)
      .map((wizard) => wizard.createOrganizationWizard),
)
