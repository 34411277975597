import { CreateContainerGroupNetworkingLoadBalancerEnum } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { ContainerGatewayDetails } from '../../../../components/containerGroups/ContainerGatewayDetails'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { Select } from '../../../../components/forms/Select'
import { ContainerGatewayFieldsMessages, CreateContainerGroupMainContentMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import { checkIfIContainerGatewayFieldsHaveErrors, containerGatewayFieldsList } from './utils'

interface ContainerGatewayFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The flag indicating if the Container Gateway side panel is open. */
  isContainerGatewaySidePanelOpen: boolean
  /** The function that sets the state of the isContainerGatewaySidePanelOpen state. */
  setIsContainerGatewaySidePanelOpen: (isContainerGatewaySidePanelOpen: boolean) => void
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const ContainerGatewayFields: FunctionComponent<ContainerGatewayFieldsProps> = ({
  control,
  id,
  isContainerGatewaySidePanelOpen,
  setIsContainerGatewaySidePanelOpen,
  trigger,
}) => {
  const intl = useIntl()
  const { errors } = useFormState({ control })
  const isContainerGatewayAuthenticationRequired = useWatch({
    control,
    name: CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION,
  })

  const containerGatewayEnabled = useWatch({ control, name: CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED })
  const isJobQueueSelected = useWatch({ control, name: CreateContainerGroupField.JOB_QUEUE })
  const isLimitedToSingleActiveConnection = useWatch({
    control,
    name: CreateContainerGroupField.CONTAINER_GATEWAY_LIMIT_SERVER,
  })
  const clientRequestTimeout = useWatch({
    control,
    name: CreateContainerGroupField.CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT,
  })
  const serverResponseTimeout = useWatch({
    control,
    name: CreateContainerGroupField.CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT,
  })
  const loadBalancerAlgorithm = useWatch({ control, name: CreateContainerGroupField.CONTAINER_GATEWAY_LOAD_BALANCER })
  const containerGatewayPort = useWatch({ control, name: CreateContainerGroupField.CONTAINER_GATEWAY_PORT })
  const containerGatewayFieldsHaveErrors = checkIfIContainerGatewayFieldsHaveErrors(errors)
  const [isContainerGatewayAuthenticated, setIsContainerGatewayAuthenticated] = useState<boolean>(
    isContainerGatewayAuthenticationRequired,
  )

  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(containerGatewayFieldsList).then((isValid) => {
      if (isValid) {
        setIsContainerGatewaySidePanelOpen(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={containerGatewayFieldsHaveErrors}
        onEditForm={containerGatewayEnabled ? () => setIsContainerGatewaySidePanelOpen(true) : undefined}
        isDisabled={isJobQueueSelected}
        title={intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayFieldsLabel)}
      >
        <p>
          {isJobQueueSelected ? (
            intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayDisabledDueToJobQueueLabel)
          ) : containerGatewayEnabled ? (
            <ContainerGatewayDetails
              clientRequestTimeout={clientRequestTimeout}
              isAuthenticationRequired={isContainerGatewayAuthenticated}
              isEnabled={containerGatewayEnabled}
              isLimitedToSingleActiveConnection={isLimitedToSingleActiveConnection}
              loadBalancerAlgorithm={loadBalancerAlgorithm}
              port={containerGatewayPort}
              serverResponseTimeout={serverResponseTimeout}
            />
          ) : (
            <Button variant="green-filled-light" onClick={() => setIsContainerGatewaySidePanelOpen(true)}>
              {intl.formatMessage(ContainerGatewayFieldsMessages.addContainerGatewayLabel)}
            </Button>
          )}
        </p>
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button variant="green-filled" form="updateContainerGatewayFieldsForm" isFullWidth type="submit">
            {intl.formatMessage(CreateContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isContainerGatewaySidePanelOpen}
        onClose={() => setIsContainerGatewaySidePanelOpen(false)}
        title={intl.formatMessage(ContainerGatewayFieldsMessages.sectionTitle)}
      >
        <form onSubmit={handleValidateFieldsBeforeClose} id="updateContainerGatewayFieldsForm">
          <div className="mt-12 px-10">
            <h2 className="mb-2 text-3xl font-bold">
              {intl.formatMessage(ContainerGatewayFieldsMessages.sectionTitle)}
            </h2>

            <p className="mb-10">
              {intl.formatMessage(ContainerGatewayFieldsMessages.description, {
                documentation_link: (
                  <Link url="https://docs.salad.com/products/sce/gateway/enabling-ipv6">
                    {intl.formatMessage(ContainerGatewayFieldsMessages.descriptionDocumentationLinkText)}
                  </Link>
                ),
              })}
            </p>

            <div className="mb-6">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...fieldState}
                      isDisabled={isJobQueueSelected}
                      isChecked={field.value as boolean}
                      id="containerGateway-enabled-checkbox"
                      label={intl.formatMessage(ContainerGatewayFieldsMessages.enableContainerGatewayLabel)}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_PORT}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      helperText={intl.formatMessage(ContainerGatewayFieldsMessages.portHelperText)}
                      label={intl.formatMessage(ContainerGatewayFieldsMessages.portLabel)}
                      isDisabled={!containerGatewayEnabled}
                      isFullWidth
                      min={1}
                      max={65535}
                      type="number"
                    />
                  )
                }}
              />
            </div>
            <div className="mb-6">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION}
                control={control}
                defaultValue={isContainerGatewayAuthenticated}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      {...fieldState}
                      defaultSelectedValue={field.value}
                      helperText={intl.formatMessage(
                        ContainerGatewayFieldsMessages.containerGatewayAuthenticationHelperText,
                      )}
                      labelText={intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayAuthenticationLabel)}
                      isDisabled={!containerGatewayEnabled}
                      isFullWidth
                      isPlaceholderOptionDisabled
                      onChange={(value) => {
                        const booleanValue = value === 'true'
                        field.onChange(booleanValue)
                        // This is needed to trigger the UI updating for the protocol field
                        setIsContainerGatewayAuthenticated(booleanValue)
                      }}
                      options={[
                        {
                          label: intl.formatMessage(ContainerGatewayFieldsMessages.yesLabel),
                          value: true,
                        },
                        {
                          label: intl.formatMessage(ContainerGatewayFieldsMessages.noLabel),
                          value: false,
                        },
                      ]}
                    />
                  )
                }}
              />
            </div>
            <div className="mb-9">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_LOAD_BALANCER}
                control={control}
                defaultValue={CreateContainerGroupNetworkingLoadBalancerEnum.LeastNumberOfConnections}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      {...fieldState}
                      defaultSelectedValue={field.value}
                      labelText={intl.formatMessage(ContainerGatewayFieldsMessages.loadBalancerAlgorithmLabel)}
                      isDisabled={!containerGatewayEnabled}
                      isFullWidth
                      isPlaceholderOptionDisabled
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                      options={[
                        {
                          label: intl.formatMessage(ContainerGatewayFieldsMessages.leastNumberOfConnectionsLabel),
                          value: CreateContainerGroupNetworkingLoadBalancerEnum.LeastNumberOfConnections,
                        },
                        {
                          label: intl.formatMessage(ContainerGatewayFieldsMessages.roundRobinLabel),
                          value: CreateContainerGroupNetworkingLoadBalancerEnum.RoundRobin,
                        },
                      ]}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-9">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_LIMIT_SERVER}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...fieldState}
                      isDisabled={isJobQueueSelected}
                      isChecked={field.value as boolean}
                      id="containerGateway-enabled-checkbox-limit-server"
                      label={intl.formatMessage(ContainerGatewayFieldsMessages.limitEachServerLabel)}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_CLIENT_REQUEST_TIMEOUT}
                control={control}
                defaultValue={100000}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      label={intl.formatMessage(ContainerGatewayFieldsMessages.clientRequestTimeoutLabel)}
                      helperText={intl.formatMessage(ContainerGatewayFieldsMessages.timeoutHelperText)}
                      isDisabled={!containerGatewayEnabled}
                      isFullWidth
                      min={1}
                      max={100000}
                      type="number"
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_SERVER_RESPONSE_TIMEOUT}
                control={control}
                defaultValue={100000}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      label={intl.formatMessage(ContainerGatewayFieldsMessages.serverResponseTimeoutLabel)}
                      helperText={intl.formatMessage(ContainerGatewayFieldsMessages.timeoutHelperText)}
                      isDisabled={!containerGatewayEnabled}
                      isFullWidth
                      min={1}
                      max={100000}
                      type="number"
                    />
                  )
                }}
              />
            </div>
          </div>
        </form>
      </SidePanelModal>
    </div>
  )
}
