import type {
  AutomaticRechargeSettings,
  BillingPaymentMethod,
  BillingPaymentMethodSetupIntentStatusEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { BillingMainContent } from './components/BillingMainContent'

export interface BillingPageProps {
  /** The automatic recharge settings. */
  automaticRechargeSettings?: AutomaticRechargeSettings
  /** The current credit amount. */
  creditAmount: number
  /** The billing credits dashboard embed url. */
  billingCreditsEmbedUrl: string | undefined
  /** The invoice dashboard embed url. */
  billingInvoiceEmbedUrl: string | undefined
  /** The billing status. */
  billingStatus: BillingPaymentMethodSetupIntentStatusEnum
  /** The billing usage dashboard embed url. */
  billingUsageEmbedUrl: string | undefined
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The flag indicating if the organization has not had a valid payment before. */
  hasNotHadValidPayment: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating if the request to load billing customer portal is pending. */
  isLoadBillingCustomerPortalPending: boolean
  /** The flag indicating if the organization onboarding wizard feature flag is enabled. */
  isOrganizationOnboardingWizardFeatureFlagEnabled: boolean
  /** The flag indicating if the request to remove payment method is pending. */
  isRemovePaymentMethodPending: boolean
  /** The flag indicating if the request to remove payment method was successful. */
  isRemovePaymentMethodSuccessful: boolean
  /** The flag indicating if the request to verify payment method is pending. */
  isPaymentMethodVerificationInProgress: boolean
  /** The flag indicating if the request to create payment method session is pending. */
  isCreatePaymentSessionInProgress: boolean
  /** The link to the form to request unrestricted access. */
  linkToRequestIncreaseForDeploymentQuotas: string
  /** The callback executed when the user clicks the add to credit balance button. */
  onAddToCreditBalance: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback executed when the user confirms the auto recharge opt out. */
  onConfirmAutoRechargeOptOut: () => void
  /** The callback executed when the user opens the form to add or update payment method. */
  onManagePaymentMethod: () => void
  /** A callback that when executed directs the user to their Metronome account where they could view their statements. */
  onManageBilling: () => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The callback executed when the user clicks the remove payment method button. */
  onRemovePaymentMethod: () => void
  /** The callback handler for when the Stripe submit form hits an error. */
  onSubmitFormError: (message?: string, status?: string) => void
  /** The callback handler for when the Stripe submit form succeeded. */
  onSubmitFormSucceeded: () => void
  /** The payment card last four digits. */
  paymentCardLastFourDigits: string
  /** The billing payment method. */
  paymentMethod: BillingPaymentMethod | undefined
  /** The billing payment method. */
  paymentMethodBillingClientSecret: string | undefined
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
}

export const BillingPage: FunctionComponent<BillingPageProps> = ({
  automaticRechargeSettings,
  billingCreditsEmbedUrl,
  billingInvoiceEmbedUrl,
  billingStatus,
  billingUsageEmbedUrl,
  creditAmount,
  currentOrganization,
  hasNotHadValidPayment,
  isCreatePaymentSessionInProgress,
  isLeftColumnOpen,
  isLoadBillingCustomerPortalPending,
  isOrganizationOnboardingWizardFeatureFlagEnabled,
  isPaymentMethodVerificationInProgress,
  isRemovePaymentMethodPending,
  isRemovePaymentMethodSuccessful,
  linkToRequestIncreaseForDeploymentQuotas,
  onAddToCreditBalance,
  onCloseLeftDrawer,
  onConfirmAutoRechargeOptOut,
  onManageBilling,
  onManagePaymentMethod,
  onProjectChange,
  onRecordMixpanelElementClickedEvent,
  onRemovePaymentMethod,
  onSubmitFormError,
  onSubmitFormSucceeded,
  paymentCardLastFourDigits,
  paymentMethod,
  paymentMethodBillingClientSecret,
  projects,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={undefined}
          isProjectSelectPlaceholderOptionSelectable={true}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <BillingMainContent
          automaticRechargeSettings={automaticRechargeSettings}
          creditAmount={creditAmount}
          billingCreditsEmbedUrl={billingCreditsEmbedUrl}
          billingInvoiceEmbedUrl={billingInvoiceEmbedUrl}
          billingStatus={billingStatus}
          billingUsageEmbedUrl={billingUsageEmbedUrl}
          hasNotHadValidPayment={hasNotHadValidPayment}
          isCreatePaymentSessionInProgress={isCreatePaymentSessionInProgress}
          isLoadBillingCustomerPortalPending={isLoadBillingCustomerPortalPending}
          isOrganizationOnboardingWizardFeatureFlagEnabled={isOrganizationOnboardingWizardFeatureFlagEnabled}
          isPaymentMethodVerificationInProgress={isPaymentMethodVerificationInProgress}
          isRemovePaymentMethodPending={isRemovePaymentMethodPending}
          isRemovePaymentMethodSuccessful={isRemovePaymentMethodSuccessful}
          linkToRequestIncreaseForDeploymentQuotas={linkToRequestIncreaseForDeploymentQuotas}
          onAddToCreditBalance={onAddToCreditBalance}
          onConfirmAutoRechargeOptOut={onConfirmAutoRechargeOptOut}
          onManageBilling={onManageBilling}
          onManagePaymentMethod={onManagePaymentMethod}
          onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
          onRemovePaymentMethod={onRemovePaymentMethod}
          onSubmitFormError={onSubmitFormError}
          onSubmitFormSucceeded={onSubmitFormSucceeded}
          organizationDisplayName={currentOrganization.displayName}
          paymentCardLastFourDigits={paymentCardLastFourDigits}
          paymentMethod={paymentMethod}
          paymentMethodBillingClientSecret={paymentMethodBillingClientSecret}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
