import { useEffect, useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Tag } from '../../../../components/Tag'
import { ButtonLink } from '../../../../components/base'
import { Card } from '../../../../components/base/Card'
import { getCreateOrganizationWizardOrganizationInformationPagePath } from '../../../../routes/routes-utils'
import SaladCloudOrgIcon from '../../assets/SaladCloudOrgIcon.png'
import { CreateOrganizationWizardCardMessages } from '../../messages'
import { OrganizationCardMessages } from '../../messages/OrganizationCardMessages'
import { DeleteCreateOrganizationWizardModal } from '../DeleteCreateOrganizationWizardModal'

export interface CreateOrganizationWizardCardProps {
  /** The create organization wizard id. */
  createOrganizationWizardId: string
  /** The create organization wizard reserved name. */
  createOrganizationWizardReservedName: string
  /** The flag for if the delete create organization wizard request is pending. */
  isDeleteCreateOrganizationWizardPending: boolean
  /** The flag for if the delete create organization wizard was successful. */
  isDeleteCreateOrganizationWizardSuccessful?: boolean
  /**
   * The callback executed when the user deletes a create organization wizard.
   *
   * @param id The id of the create organization wizard that is being deleted.
   */
  onDeleteCreateOrganizationWizard: (id: string) => void
}

export const CreateOrganizationWizardCard: FunctionComponent<CreateOrganizationWizardCardProps> = ({
  createOrganizationWizardId,
  createOrganizationWizardReservedName,
  isDeleteCreateOrganizationWizardPending,
  isDeleteCreateOrganizationWizardSuccessful,
  onDeleteCreateOrganizationWizard,
}) => {
  const intl = useIntl()

  const [isDeleteCreateOrganizationWizardModalOpen, setIsDeleteCreateOrganizationWizardModalOpen] =
    useState<boolean>(false)

  useEffect(() => {
    setIsDeleteCreateOrganizationWizardModalOpen(false)
  }, [isDeleteCreateOrganizationWizardSuccessful])

  return (
    <div className="mb-4">
      <Card>
        <div className="flex flex-wrap">
          <div className="mr-2 flex flex-col">
            <img
              alt={intl.formatMessage(OrganizationCardMessages.saladCloudOrgIconAlt)}
              height={48}
              src={SaladCloudOrgIcon}
              width={48}
            />
          </div>
          <div>
            <div className="mb-1 flex flex-row">
              <Tag color="gray">
                {intl.formatMessage(CreateOrganizationWizardCardMessages.createOrganizationWizardCardTitle)}
              </Tag>
            </div>
            <div className="mb-4 flex flex-row gap-2">
              <p className="font-bold">
                {createOrganizationWizardReservedName ??
                  intl.formatMessage(CreateOrganizationWizardCardMessages.nameSelectionPendingDefaultText)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-40">
            <ButtonLink
              url={getCreateOrganizationWizardOrganizationInformationPagePath(createOrganizationWizardId)}
              variant="green-filled-light"
              isFullWidth
            >
              {intl.formatMessage(CreateOrganizationWizardCardMessages.continueButtonLabel)}
            </ButtonLink>
          </div>
          <div>
            <Button
              variant="red-text"
              type="button"
              isFullWidth
              onClick={() => setIsDeleteCreateOrganizationWizardModalOpen(true)}
            >
              {intl.formatMessage(CreateOrganizationWizardCardMessages.deleteButtonLabel)}
            </Button>
          </div>
        </div>
      </Card>

      {isDeleteCreateOrganizationWizardModalOpen && (
        <DeleteCreateOrganizationWizardModal
          isDeleteCreateOrganizationWizardPending={isDeleteCreateOrganizationWizardPending}
          onCancel={() => setIsDeleteCreateOrganizationWizardModalOpen(false)}
          onDeleteCreateOrganizationWizard={() => onDeleteCreateOrganizationWizard(createOrganizationWizardId)}
          createOrganizationWizardReservedName={createOrganizationWizardReservedName}
        />
      )}
    </div>
  )
}
