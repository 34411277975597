import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../../components/block/HelpScoutSupportLink'
import type { ToastNotificationType } from '../../models'
import { turnstileInitializationProblemsErrorMessages } from './messages'

/**
 * Retrieves the content needed for a Turnstile initialization problems error toast notification.
 *
 * @param errorCode The specific error code returned by the Turnstile service.
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getTurnstileInitializationProblemsErrorContent = (
  errorCode: string,
  intl: IntlShape,
): { body: ReactNode; type: ToastNotificationType; title: string } => ({
  body: intl.formatMessage(turnstileInitializationProblemsErrorMessages.body, {
    errorCode,
    contact_support: (
      <HelpScoutSupportLink
        linkedText={intl.formatMessage(turnstileInitializationProblemsErrorMessages.contactSupportLinkText)}
        classNames="text-blue-90 underline"
      />
    ),
  }),
  title: intl.formatMessage(turnstileInitializationProblemsErrorMessages.title),
  type: 'error',
})
