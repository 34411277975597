import { defineMessages } from 'react-intl'

export const EditDigitalOceanContainerRegistryInputFieldsMessages = defineMessages({
  personalAccessTokenHelperText: {
    defaultMessage: 'Generate your DigitalOcean Personal Access Token and paste here',
    id: '7rYCbJ0J',
    description: 'The helper text for the Digital Ocean Container Registry personal access token input field.',
  },
  personalAccessTokenLabel: {
    defaultMessage: 'Personal Access Token',
    id: 'dqjXY2z3',
    description: 'The label for the Digital Ocean Container Registry personal access token input field.',
  },
  personalAccessTokenPlaceholderText: {
    defaultMessage: 'Your Personal Access Token',
    id: 'Lv4IU/8e',
    description: 'The placeholder text for the Digital Ocean Container Registry personal access token input field.',
  },
  personalAccessTokenRequiredText: {
    defaultMessage: 'Personal Access Token is required',
    id: 'MEZzf0VY',
    description: 'The required text for the Digital Ocean Container Registry personal access token input field.',
  },
  usernameHelperText: {
    defaultMessage: 'Your DigitalOcean Account Email Address',
    id: 'r1M2GU9g',
    description: 'The helper text for the Digital Ocean Container Registry username input field.',
  },
  usernameLabel: {
    defaultMessage: 'Username',
    id: '1zP7n1GF',
    description: 'The label for the Digital Ocean Container Registry username input field.',
  },
  usernamePlaceholderText: {
    defaultMessage: 'Your Username',
    id: 'gI9f25SA',
    description: 'The placeholder text for the Digital Ocean Container Registry username input field.',
  },
  usernameRequiredText: {
    defaultMessage: 'Username is required',
    id: 'r1OwcsU2',
    description: 'The required text for the Digital Ocean Container Registry username input field.',
  },
})
