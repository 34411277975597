import { defineMessages } from 'react-intl'

export const EditCommandFieldsMessages = defineMessages({
  addArgumentButtonLabel: {
    defaultMessage: 'Add Argument',
    id: 'uvFIX3bW',
    description: 'The label for the add argument button.',
  },
  argumentCardHeader: {
    defaultMessage: 'Argument {count} (Optional)',
    id: 'dGfhztyu',
    description: 'The header for the argument field card.',
  },
  argumentFieldPlaceholder: {
    defaultMessage: '--argument1=true',
    id: 'Cj0Gd282',
    description: 'The placeholder for the command argument field.',
  },
  commandFieldErrorMessage: {
    defaultMessage: 'Command is required when arguments are specified.',
    id: 'XB4AxSdf',
    description: 'The error message for the command field when arguments are provided and the field is empty.',
  },
  commandFieldLabel: {
    defaultMessage: 'Command',
    id: 'NfVrkd5I',
    description: 'The label for the command field.',
  },
  commandFieldPlaceholder: {
    defaultMessage: 'cat',
    id: 'K6glSZk+',
    description: 'The placeholder for the command field.',
  },
  configureButtonLabel: {
    defaultMessage: 'Configure',
    id: 'qkejdOUt',
    description: 'The label for the configure button.',
  },
  description: {
    defaultMessage:
      'Pass a command (and optional arguments) to override the ENTRYPOINT and CMD of a container image. If a Command is specified any ENTRYPOINT and CMD set in the dockerfile are overridden. If needed, command can be used to add additional arguments to a container, by including the existing CMD or ENTRYPOINT of the image. {learn_more_here_link}',
    id: 'sSIkYdkV',
    description: 'The description for the command fields.',
  },
  dockerErrorMessage: {
    defaultMessage: 'The Command field should not be used for a docker command. ',
    id: 'WcRZ5IdW',
    description: 'The error text shown if docker is in the command.',
  },
  spaceErrorMessage: {
    defaultMessage: `You cannot use spaces with Command. If you're attempting to add arguments, please do those separately by using the “Add Argument” button.`,
    id: 'dykuBRSg',
    description: 'The error text shown if space character is in the command.',
  },
  learMoreLinkText: {
    defaultMessage: 'Learn more here',
    id: 'O+G+PtSk',
    description: 'The text for the learn more link.',
  },

  removeArgumentButtonLabel: {
    defaultMessage: 'Remove Argument',
    id: 'gsHosDHo',
    description: 'The label for the remove argument button.',
  },
})
