import { defineMessages } from 'react-intl'

export const AddNewPaymentMethodSucceededMessages = defineMessages({
  body: {
    defaultMessage:
      'Your payment method has been added successfully. Once it is validated, your quotas will be increased and you can deploy on SaladCloud.',
    id: '2/8kGsHj',
    description:
      'The description shown in a toast notification message shown when the new payment method was successfully added.',
  },
  title: {
    defaultMessage: 'Payment method added successfully',
    id: 'vwF69h7n',
    description: 'The toast notification title shown when the new payment method was successfully added.',
  },
})
