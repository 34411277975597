import type { StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { HelpScoutSupportLink } from '../../../../components/block/HelpScoutSupportLink'
import { InlineNotification } from '../../../../components/block/InlineNotification'
import { FeaturePreviewPill } from '../../../../components/featurePreview/FeaturePreviewPill'
import { RadioCards } from '../../../../components/forms/RadioCards'
import { DiskSpaceInputMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import { generateDiskSpaceOptionCards, getDiskSpaceSelectedIsHighDemand } from './utils'

interface DiskSpaceInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the field. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const DiskSpaceInput: FunctionComponent<DiskSpaceInputProps> = ({ control, id, storageOptions }) => {
  const intl = useIntl()
  const storageOptionsUnavailable = storageOptions === undefined
  const options = storageOptions || []
  const selectedDiskSpace: number = useWatch({ control, name: CreateContainerGroupField.DISK_SPACE })
  const diskSpaceSelectIsInHighDemand = getDiskSpaceSelectedIsHighDemand(options || [], selectedDiskSpace)

  return (
    <div id={id}>
      <div className="mb-2">
        <FeaturePreviewPill />
      </div>
      <Controller
        name={CreateContainerGroupField.DISK_SPACE}
        control={control}
        render={({ field, fieldState }) => (
          <RadioCards
            {...field}
            {...fieldState}
            cards={generateDiskSpaceOptionCards(options)}
            defaultCheckedValue={field.value}
            hasNoneSelectedOption
            helperText={
              storageOptionsUnavailable
                ? intl.formatMessage(DiskSpaceInputMessages.unableToGetOptionsText, {
                    contact_support: (
                      <HelpScoutSupportLink
                        linkedText={intl.formatMessage(DiskSpaceInputMessages.helpScoutLinkText)}
                        classNames="text-blue-90 underline"
                      />
                    ),
                  })
                : intl.formatMessage(DiskSpaceInputMessages.helperText, {
                    contact_us_link: (
                      <HelpScoutSupportLink
                        linkedText={intl.formatMessage(DiskSpaceInputMessages.contactUsLinkText)}
                        classNames="text-blue-90 underline"
                      />
                    ),
                  })
            }
            invalid={fieldState.invalid || storageOptionsUnavailable}
            label={intl.formatMessage(DiskSpaceInputMessages.label)}
            name={CreateContainerGroupField.DISK_SPACE}
          />
        )}
      />
      {diskSpaceSelectIsInHighDemand && (
        <InlineNotification
          body={intl.formatMessage(DiskSpaceInputMessages.highDemandNotificationBody)}
          title={intl.formatMessage(DiskSpaceInputMessages.highDemandNotificationTitle)}
          type="info"
        />
      )}
    </div>
  )
}
