import { defineMessages } from 'react-intl'

export const createOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeThresholdErrorContentMessages =
  defineMessages({
    body: {
      defaultMessage:
        'There was an error saving your automatic recharge threshold. Try again and if this issue persists {contact_support}.',
      id: 'J4d9dEau',
      description:
        'The description shown in a toast notification message when we encounter an error on trying to configure the automatic recharge threshold.',
    },
    contactSupportLinkText: {
      defaultMessage: 'contact support',
      id: 'K3W/SF6o',
      description:
        'The text shown for the contact support link in the configure credit settings error toast notification.',
    },
    title: {
      defaultMessage: 'Unable to configure automatic recharge threshold',
      id: 'z/vyyRuH',
      description:
        'The toast notification title shown when we encounter an error on trying to configure the automatic recharge threshold.',
    },
  })
