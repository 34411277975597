import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { DigitalOceanContainerRegistryInputFieldsMessages } from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface DigitalOceanContainerRegistryInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const DigitalOceanContainerRegistryInputFields: FunctionComponent<
  DigitalOceanContainerRegistryInputFieldsProps
> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={CreateContainerGroupField.DIGITAL_OCEAN_USERNAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={
              fieldState.error?.message ??
              intl.formatMessage(DigitalOceanContainerRegistryInputFieldsMessages.usernameHelperText)
            }
            label={intl.formatMessage(DigitalOceanContainerRegistryInputFieldsMessages.usernameLabel)}
            placeholder={intl.formatMessage(DigitalOceanContainerRegistryInputFieldsMessages.usernamePlaceholderText)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={CreateContainerGroupField.DIGITAL_OCEAN_PERSONAL_ACCESS_TOKEN}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={
              fieldState.error?.message ??
              intl.formatMessage(DigitalOceanContainerRegistryInputFieldsMessages.personalAccessTokenHelperText)
            }
            label={intl.formatMessage(DigitalOceanContainerRegistryInputFieldsMessages.personalAccessTokenLabel)}
            placeholder={intl.formatMessage(
              DigitalOceanContainerRegistryInputFieldsMessages.personalAccessTokenPlaceholderText,
            )}
            type="password"
            isFullWidth
          />
        )}
      />
    </>
  )
}
