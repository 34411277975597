import type { Queue } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { selectJobQueueConnectionsTableItems } from '../../features/jobQueueConnectionsTable/jobQueueConnectionsTableSelectors'
import { configuresJobQueueConnectionsTableEntityId } from '../../features/jobQueueConnectionsTable/utils'
import { selectJobQueue } from '../../features/jobQueues/jobQueuesSelectors'
import {
  deleteJobQueue,
  getJobQueueDetailsPageData,
  stopPollingForJobQueueDetails,
} from '../../features/jobQueues/jobQueuesSlice'
import { configuresJobQueuesEntityId } from '../../features/jobQueues/utils'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRequestStatus, selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { getEditJobQueuePagePath, getJobQueuesPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { JobQueueDetailsPage } from './JobQueueDetailsPage'

export const ConnectedJobQueueDetailsPage: FunctionComponent = () => {
  const { jobQueueName = '', organizationName = '', projectName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isGetJobQueueDetailsPageDataRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getJobQueueDetailsPageData'),
  )
  const isDeleteJobQueueRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'deleteJobQueue'),
  )
  const jobQueue = useAppSelector((state) =>
    selectJobQueue(state, configuresJobQueuesEntityId(organizationName, projectName), jobQueueName),
  )
  const jobQueueConnections = useAppSelector((state) =>
    selectJobQueueConnectionsTableItems(
      state.jobQueueConnectionsTable,
      configuresJobQueueConnectionsTableEntityId(organizationName, projectName, jobQueueName),
    ),
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getJobQueuesPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const onDeleteJobQueue = useCallback(
    (queueName: string) => {
      dispatch(deleteJobQueue({ organizationName, projectName, queueName }))
    },
    [dispatch, organizationName, projectName],
  )

  const onGetJobQueueDetailsPageData = useCallback(() => {
    dispatch(
      getJobQueueDetailsPageData({
        organizationName,
        projectName,
        jobQueueName,
      }),
    )
  }, [dispatch, jobQueueName, organizationName, projectName])

  useEffect(() => {
    onGetJobQueueDetailsPageData()
  }, [onGetJobQueueDetailsPageData])

  useEffect(() => {
    return () => {
      dispatch(stopPollingForJobQueueDetails())
    }
  }, [dispatch])

  return isGetJobQueueDetailsPageDataRequestPending || jobQueue === undefined ? (
    <LoadingPage />
  ) : (
    <JobQueueDetailsPage
      connections={jobQueueConnections}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      editJobQueuePath={getEditJobQueuePagePath(organizationName, projectName, jobQueueName)}
      isDeleteJobQueuePending={isDeleteJobQueueRequestPending}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isLeftColumnOpen={isLeftColumnOpen}
      jobQueue={jobQueue as Queue}
      jobQueuesPath={getJobQueuesPagePath(organizationName, projectName)}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteConnection={() => {}} // TODO: Implement when feature is available
      onDeleteJobQueue={onDeleteJobQueue}
      onDeleteProject={handleDeleteProject}
      onProjectChange={handleProjectChange}
      projects={projectSelectOptions}
    />
  )
}
