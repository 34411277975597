import { defineMessages } from 'react-intl'

export const OrganizationsMainContentMessages = defineMessages({
  createOrganizationButtonLabel: {
    defaultMessage: 'Create a New Organization',
    id: '+E7Zojl2',
    description: 'The label for the create organization button.',
  },
  createOrganizationWithWizardButtonLabel: {
    defaultMessage: 'Create a New Organization with Wizard',
    id: 'HKxHXe6w',
    description: 'The label for the create organization with wizard button.',
  },
  description: {
    defaultMessage: 'Organizations allow you to share access to projects with other users.',
    id: 'UzF3Eufl',
    description: 'The description for the organizations main content.',
  },
  title: {
    defaultMessage: 'Your Organizations',
    id: 'dS6/9Zov',
    description: 'The title for the organizations main content.',
  },
})
