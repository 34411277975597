import { defineMessages } from 'react-intl'

export const DeleteCreateOrganizationWizardModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'nBCWWfU4',
    description:
      'The label for the Cancel button on the modal that shows when a user wants to delete an organization wizard.',
  },
  deleteButtonLabel: {
    defaultMessage: 'Delete',
    id: 'g3ugomYE',
    description:
      'The label for the delete button on the modal that shows when a user wants to delete an organization wizard.',
  },
  description: {
    defaultMessage: `Are you absolutely sure? This will permanently delete your progress in creating {reserved_organization_name}, and remove your organization name reservation, if one exists. You cannot undo this.`,
    id: 'ohGQsqW8',
    description: 'The description for the modal that shows when a user wants to delete an organization wizard.',
  },
  title: {
    defaultMessage: 'Delete your pending organization',
    id: 'Zz8HttNv',
    description: 'The title for the modal that shows when a user wants to delete an organization wizard.',
  },
})
